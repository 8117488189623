import React from 'react';
// import { Route } from 'react-router';
import { withRouter } from 'react-router-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import  Layout  from './components/Layout';
import ImageDetails from './components/pages/image-details-page/image-details';
import LandingPage from './components/pages/landing-page/landing-page';
import SearchResulsPage from './components/pages/search-results-page/search_results_page';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './custom.css'
import FAQPage from './components/pages/faq-page/faq-page';

function App() {

    // Call it once in your app.
    toast.configure({
      pauseOnHover: false,
      position: toast.POSITION.BOTTOM_RIGHT,
      theme:"dark",
      autoClose: 1500
  });

    return (
      <Layout>
          <Route exact path='/' component={withRouter(LandingPage)} /> 
          <Route path="/search" component={SearchResulsPage} />
          <Route path="/image-details/:projectId/:imageName" component={ImageDetails} />
          <Route path="/faqs" component={FAQPage} />
          {/* <Footer isFixed={false} ></Footer> */}

      </Layout>
    );
  }

  export default App;