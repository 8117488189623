import './side-bar.css'
import React from "react";
import {
    Nav
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faArrowLeft
} from '@fortawesome/free-solid-svg-icons'

function SideBar(props) {


    /**
     * Called to close the sidebar
     * */
    function close() {
        props.closeSidebar(false);
    }


    /**
     * Render
     * */
    return (
        // className={(props.showFilters) ? "border-right mt-3 sidebar active" : "border-right mt-3 sidebar"
        // style={{ zIndex: props.zIndex, marginLeft: (props.showFilters) ? 0 : -225 }} className={"border-right mt-3 sidebar"}
        <nav style={{ zIndex: props.zIndex, marginLeft: (props.showFilters) ? 0 : -300}} className={"border-right mt-3 sidebar"}>
            {props.showHeader
                ?
                <Nav as="ul" className="justify-content-between mb-3">
                    <Nav.Item as="li" disabled>
                        <Nav.Link className="p-2 text-capitalize" disabled>{props.headerTitle}</Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li">
                        <Nav.Link className="p-2">
                            <FontAwesomeIcon className="text-primary" onClick={close} icon={faArrowLeft} />
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
                :
                <></>}
            {props.children}
        </nav>
    );
}
export default SideBar;
