import _ from "underscore";
import { filterSearch } from './actions';
import SearchService from '../../services/search-service';
// import notification from '../../components/shared/notification/notification';

export const SET_SHOW_FILTERS = 'SET_SHOW_FILTERS';
export const SHOW_CHILD_FILTERS = 'SHOW_CHILD_FILTERS';
export const SET_PARENT_FILTERS = 'SET_PARENT_FILTERS';
export const APPEND_FILTER = 'APPEND_FILTER';
export const UPDATE_FILTER_DATA = 'UPDATE_FILTER_DATA';
export const RESET_FILTERS = "RESET_FILTERS";
export const UPDATE_FILTER_IS_LOADING = 'UPDATE_FILTER_IS_LOADING';
export const UPDATE_FILTER_IS_COLLAPSED = 'UPDATE_FILTER_IS_COLLAPSED';
export const SET_CHILD_FILTERS_LOADING = 'SET_CHILD_FILTERS_LOADING';
export const SET_IS_DATA_LOADED = 'SET_IS_DATA_LOADED';


// Show/Hide Fitlers as needed
export const setShowFilters = (showFilters) => {
    return { type: SET_SHOW_FILTERS, payload: showFilters };
}
//Show child filters when needed and available 
export const showChildFilters = (showchild) => {
    return { type: SHOW_CHILD_FILTERS, payload: showchild };
}

// Set is filter data loading based on the filter key
export const setIsDataLoaded = (isLoaded, key) => {
    var data = { isLoaded: isLoaded, key: key };
    return { type: SET_IS_DATA_LOADED, payload: data };
}

//Create the client side filter container object
export const createFilterContainers = (filters, parent) => {
    return async (dispatch, getState) => {
        filters = _.sortBy(filters, 'position');

        var filterData = (getState().filterReducer.filters) ?
            JSON.parse(JSON.stringify(getState().filterReducer.filters)) :
            {};

        _.each(filters, function (filter) {
            filterData[filter.name] = {
                key: filter.name,
                action: filter.childMetadataUrl,
                parent: (parent) ? parent : filter.name,
                name: filter.name,
                title: filter.display,
                isSearchable: filter.type === "multi-list",
                url: filter.dataUrl,
                offset: 0,
                isLoading: true,
                filters: [],
                type: filter.type,
                isLazyLoad: filter.isLazyLoad,
                hasCounts: filter.hasCounts,
                isCollapsed: filter.isCollapsed,
                isDataLoaded: !filter.isCollapsed,
                selectedFilters: (filter.defaults !== null) ? filter.defaults : []
            };
        });
        await dispatch(resetFilters(filterData));
        dispatch(setFilterValues(filterData, filters));
    }
}

// Reset filters
export const resetFilters = (filters) => {
    return { type: RESET_FILTERS, payload: filters };
}

// Get more filters
export const getMoreFilters = (filter) => {
    return async (dispatch, getState) => {
        SearchService.getFilterData(filter.url, getState().searchReducer.scid, filter.offset, false).then(response => {
            var filterState = [];
            _.each(Object.keys(response), function (key, index) {
                var stateObject = {
                    id: index,
                    name: key,
                    count: response[key],
                    isVisible: true,
                    isAvailable: 1,
                    isChecked: false,
                }
                filterState.push(stateObject);
            });
            dispatch(appendFilter(filterState, filter.key, filter.parent))
            dispatch(updateFilterIsLoading(false, filter.key));
        });
    }
}

//Toggle filter (Checked/Unchecked)
export const toggleFilter = (filter, key, parent) => {
    return async (dispatch, getState) => {
        if (await getState().searchReducer.searchResultsLoading)
            return;

        // dispatch(showChildFilters(false));
        var newFilterState = getState().filterReducer.filters[key].filters.slice();
        _.each(newFilterState, function (oldFilter) {
            if (oldFilter.name === filter.name) {
                filter.isChecked = !filter.isChecked;
            }
        });

        if (filter.name === getState().filterReducer.parentFilterName && !filter.isChecked) {
            dispatch(deselectChildFilters(filter.name));
            dispatch(showChildFilters(false));
            dispatch(setParentFilter(""));
        }

        await dispatch(updateFilterData(newFilterState, key, parent));
        dispatch(filterSearch());
    }
}

//Toggle filter (Checked/Unchecked)
export const setDateFilter = (filter, key, parent) => {
    return async (dispatch, getState) => {
        if (await getState().searchReducer.searchResultsLoading)
            return;

        if (filter === null) { 
            await dispatch(updateFilterData([], key, parent));
        } else {
            var dateFilter = {
                id: 0,
                name: filter,
                isVisible: true,
                isAvailable: 1,
                isChecked: true,
            }
            await dispatch(updateFilterData([dateFilter], key, parent));
         }

        dispatch(filterSearch());
    }
}

// Update filter data 
export const updateFilterData = (filters, key, parent) => {
    var data = { filters: filters, key: key, parent: parent };
    return { type: UPDATE_FILTER_DATA, payload: data };
}

// Cross Filter - updates counts and avaiable filters
export const crossFilter = (filters) => {
    return (dispatch, getState) => {
        _.each(filters, function (filter) {
            if (filter.dataUrl !== "") {
                SearchService.getFilterData(filter.dataUrl, getState().searchReducer.scid, 0, true).then(response => {
                    var oldStateFilters = getState().filterReducer.filters[filter.name].filters.slice();
                    var newStateFilters = [];
                    _.each(Object.keys(response), function (key) {
                        var checkedFilter = _.find(oldStateFilters, function (existingFilter) {
                            return existingFilter.isChecked && existingFilter.name === key;
                        });
                        if (!checkedFilter) {
                            var stateObject = {
                                id: newStateFilters.length + 1,
                                name: key,
                                count: response[key],
                                isVisible: true,
                                isAvailable: response[key] > 0 ? 1 : 0,
                                isChecked: false,
                            }
                            newStateFilters.push(stateObject);
                        } else {
                            checkedFilter.count = response[key];
                            checkedFilter.isAvailable = (response[key] > 0) ? 1 : 0;
                            newStateFilters.push(checkedFilter);
                        }
                    });
                    newStateFilters = _.sortBy(newStateFilters, 'count').reverse();
                    dispatch(updateFilterData(newStateFilters, filter.name));
                });
            }
        });
    }
}

// Get child filters when needed
export const getChildFilters = (url, parentKey) => {
    return (dispatch, getState) => {
        if (parentKey === getState().filterReducer.parentFilterName)
            return;
        var awaitOpen = false
        dispatch(setParentFilter(parentKey));
        if (getState().filterReducer.showChildFilters) {
            awaitOpen = true;
            dispatch(showChildFilters(false));
            setTimeout(() => { dispatch(showChildFilters(true)); }, 125);
        } else {
            dispatch(showChildFilters(true));
        }

        var isChildCached = _.some(getState().filterReducer.filters, (filterContainer) => {
            if (filterContainer.parent === parentKey) return true;
        });

        if (!isChildCached) {
            dispatch(setChildFilterLoading(true));
            SearchService.getFilterMetaData(url, parentKey, getState().searchReducer.scid).then(response => {
                if (response !== null) {
                    setTimeout(() => {
                        dispatch(createFilterContainers(response, parentKey));
                        dispatch(setChildFilterLoading(false));
                    }, 250);
                }
            });
        } else {
            if (!awaitOpen)
                dispatch(showChildFilters(true));
        }
    }
}

// Set Parent filter 
export const setParentFilter = (parentFilter) => {
    return { type: SET_PARENT_FILTERS, payload: parentFilter };
}

export const updateFilterIsCollapsed = (isCollapsed, key, parent) => {
    var data = { isCollapsed: isCollapsed, key: key };
    return { type: UPDATE_FILTER_IS_COLLAPSED, payload: data };
}



const setFilterValues = (filterContainers, filters) => {
    return (dispatch, getState) => {
        _.each(filters, function (filter) {
            var filterState = [];
            if (filter.isCollapsed) {
                _.each(filter.defaults, function (name, index) {
                    var stateObject = {
                        id: index,
                        name: name,
                        count: 10,
                        isVisible: true,
                        isAvailable: 1,
                        isChecked: true,
                    }
                    filterState.push(stateObject);
                });
                if (filterState.length > 0) {
                    dispatch(appendFilter(filterState, filter.name, filterContainers[filter.name].parent))
                    dispatch(updateFilterIsLoading(false, filter.name));
                }
            } else {
                if (filter.dataUrl !== "") {
                    SearchService.getFilterData(filter.dataUrl, getState().searchReducer.scid, 0, false).then(response => {
                        if (response) {
                           
                            _.each(Object.keys(response), function (key, index) {
                                var stateObject = {
                                    id: index,
                                    name: key,
                                    count: response[key],
                                    isVisible: true,
                                    isAvailable: 1,
                                    isChecked: filterContainers[filter.name].selectedFilters.includes(key),
                                }
                                filterState.push(stateObject);
                            });
                            //Save the filter
                            filterState = _.sortBy(filterState, 'count').reverse();
                            dispatch(appendFilter(filterState, filter.name, filterContainers[filter.name].parent))
                            dispatch(updateFilterIsLoading(false, filter.name));
                        } else {
                            // notification.notifyError(`Problem getting ${filterContainers[filter.name].title} data`);
                        }
                    });
                } else {
                    dispatch(appendFilter(filterState, filter.name, filterContainers[filter.name].parent))
                    dispatch(updateFilterIsLoading(false, filter.name))
                }
            }
        });
    }
}

const updateFilterIsLoading = (isLoading, key, parent) => {
    var data = { isLoading: isLoading, key: key };
    return { type: UPDATE_FILTER_IS_LOADING, payload: data };
}

const setChildFilterLoading = (isLoading) => {
    return { type: SET_CHILD_FILTERS_LOADING, payload: isLoading };
}

const appendFilter = (filters, key, parent) => {
    var data = { filters: filters, key: key, parent: parent };
    return { type: APPEND_FILTER, payload: data };
}

const deselectChildFilters = (parentFilter) => {
    return async (dispatch, getState) => {
        var childFilterContainers = _.filter(getState().filterReducer.filters, (filterContainer) => {
            return filterContainer.parent === parentFilter
        });
        _.each(childFilterContainers, function (childFilterContainer) {
            if (childFilterContainer.filters.length > 0) {
                var newChildFilterState = childFilterContainer.filters.slice();
                _.each(newChildFilterState, function (childFilter) {
                    if (childFilter.isChecked) {
                        childFilter.isChecked = false;
                    }
                });
                dispatch(updateFilterData(newChildFilterState, childFilterContainer.key, childFilterContainer.parent));
            }
        });
    }
}
