import axios from 'axios';
// import notification from '../components/shared/notification/notification';
import config from '../config';
import { authProvider } from './auth-service';

axios.defaults.headers.get['Pragma'] = 'no-cache';
axios.defaults.headers.get['Cache-Control'] = 'no-cache, no-store';

export default class SearchService {

    /**
     * Search
     * @param {any} action
     */
    static search = async (term, type, scid, filters, sort) => {
        var queryParameters = ((type === "") ? "" : "&type=" + encodeURIComponent(type)) +
            ((scid === "") ? "" : "&scid=" + scid) +
            ((filters === "") ? "" : filters) +
            ((sort === "") ? "" : sort);
        
        var token = await authProvider.getAccessToken();
        return axios.get(config.apiUrl + 'api/search?query=' + encodeURIComponent(term) + queryParameters,
            {
                headers: {
                    Authorization: 'Bearer ' + token.accessToken,
                }
            }
        )
            .then((response) => {
                if (response.status === 200) {
                    return response.data;
                }
                throw response;
            })
            .catch((error) => {
                console.log(error);
                // notification.notifyError(error.response.data.Detail);
                return null;
            })
    };


    /**
     * Get Next Page
     * @param {any} action
     */
    static searchNextPage = async (term, scid, offset) => {
        var token = await authProvider.getAccessToken();
        return axios.get(config.apiUrl + 'api/search/results?scid=' + scid + "&offset=" + offset,
            {
                headers: {
                    Authorization: 'Bearer ' + token.accessToken,
                }
            }
        ).then((response) => {
            if (response.status === 200) {
                return response.data;
            }
            throw response;
        })
            .catch((error) => {
                console.log(error);
                // notification.notifyError(error.response.data.Detail);
                return null;
            })
    }


    /**
 * Get Next Page
 * @param {any} action
 */
    static getDocumentById = async (scid, id) => {
        var token = await authProvider.getAccessToken();
        return axios.get(config.apiUrl + 'api/search/getById?scid=' + scid + "&docId=" + id,
            {
                headers: {
                    Authorization: 'Bearer ' + token.accessToken,
                }
            }
        ).then((response) => {
            if (response.status === 200) {
                return response.data;
            }
            throw response;
        })
            .catch((error) => {
                console.log(error);
                // notification.notifyError(error.response.data.Detail);
                return null;
            })
    }

    /**
 * Service to get Class Filters
 * @param {any} action
 */
    static getFilterData = async (url, scid, offset, crossfilter) => {
        var token = await authProvider.getAccessToken();
        // `${config.apiUrl}${url}?scid=${scid}&offset=${offset}&fetch=5&recountPrevious=${recountPrevious}`
        return axios.get(`${config.apiUrl}${url}?scid=${scid}&offset=${offset}&fetch=5&crossfilter=${crossfilter}`,
            {
                headers: {
                    Authorization: 'Bearer ' + token.accessToken,
                }
            }
        )
            .then((response) => {
                if (response.status === 200) {
                    return response.data;

                }
                throw response;
            })
            .catch((error) => {
                console.log(error);
                // notification.notifyError(error.response.data.Detail);
                return null;
            })
    }


    /**
* Service to get Class Filters
* @param {any} action
*/
    static getFilterMetaData = async (url, parentKey, scid) => {
        var token = await authProvider.getAccessToken();
        var urlSplit = url.split('#');
        return axios.get(config.apiUrl + urlSplit[0] + parentKey + "&scid=" + scid,
            {
                headers: {
                    Authorization: 'Bearer ' + token.accessToken,
                }
            }
        ).then((response) => {
                if (response.status === 200) {
                    return response.data;
                }
                throw response;
            })
            .catch((error) => {
                console.log(error);
                // notification.notifyError(error.response.data.Detail);
                return null;
            })
    }
}