import React from "react";
import { useDispatch, useSelector } from "react-redux";
// import _ from "underscore";
import { Navbar, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import {
    setShowFilters
} from '../../../redux/actions/actions'
// import Chip from '../../shared/chip/chip';

function SummaryNavbar(props) {

    //Redux State
    const showFilters = useSelector(state => state.filterReducer.showFilters);
    // const filterContainers = useSelector(state => state.filterReducer.filters);
    // const specs = useSelector(state => state.filterReducer.cachedSpecFilters);
    const dispatch = useDispatch();

    //State
    // const [isLoading, setIsLoading] = useState(false);


    /**
     * Calucates the max pages based on the search
     */
    // const getMaxPages = () => {
    //     //Check if a secondary search has occured
    //     var itemCount = (props.matchesFound !== props.secondaryMatchesFound) ? props.secondaryMatchesFound : props.matchesFound;
    //     return Math.ceil(itemCount / props.pageSize);
    // }

    /**
     *Show and hide sidebar with filters
     */
    const toggleFilters = () => {
        dispatch(setShowFilters(!showFilters))
    }


    /**
     * Render
     */
    return (
        <Navbar bg="secondary" className="text-center subnav" style={{ minHeight: '40px' }}>
            <Button variant="light" className="text-primary" onClick={toggleFilters}>
                <FontAwesomeIcon icon={faFilter} />
            </Button>
            {/* <div className="container-fluid">
                <form className="form-inline">
                    {
                        filterContainers && Object.keys(filterContainers).length > 0 ?
                            Object.keys(filterContainers).map((key, index) => (
                                filterContainers[key].filters && filterContainers[key].filters.map(function (filter, i) {
                                    if (filter.isChecked)
                                        return <Chip
                                            key={filter.name}
                                            filter={filter}
                                            filterKey={key}
                                            filterType={filterContainers[key].type}
                                            displayName={filterContainers[key].parent === filterContainers[key].name ? filter.name : `${filterContainers[key].parent} - ${filter.name}`}
                                            parent={filterContainers[key].parent}
                                        />
                                })
                            ))
                        :
                        <></>
                    }
                </form>
            </div> */}
        </Navbar>
    );
}
export default SummaryNavbar;

