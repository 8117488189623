import {
    SET_PAGE_RESULTS,
    SET_IS_RESULTS_LOADING,
    SET_IS_SHOWING_SIDEBAR
} from '../actions/actions';

/*
Create intial state for the app
All application level state or global selected values should be stored here
*/
const appInitialState = {
    pageResults: [],
    isLoading: true,
    showSideBar: true
};

const appReducer = (state = appInitialState, action) => {
    switch (action.type) {
        case SET_PAGE_RESULTS:
            return {
                ...state,
                pageResults: state.pageResults.concat(action.payload)
            };
        case SET_IS_RESULTS_LOADING:
            return {
                ...state,
                isLoading: action.payload
            }
        case SET_IS_SHOWING_SIDEBAR:
            return {
                ...state,
                showSideBar: action.payload
            }
        default:
            return state;
    };
}

export default appReducer;
