import './map-wrapper.css';
import React, { useState, useEffect, useRef } from 'react';

// openlayers
import Map from 'ol/Map'
import View from 'ol/View'
import TileLayer from 'ol/layer/Tile'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import XYZ from 'ol/source/XYZ'
import { transform } from 'ol/proj'
import Overlay from 'ol/Overlay';
import GeoJSON from 'ol/format/GeoJSON';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';


function MapWrapper(props) {

    // set intial state
    // const styleCache = {};
    const [map, setMap] = useState()
    // const [popUpContainer, setPopUpContainer] = useState();
    // const [featuresLayer, setFeaturesLayer] = useState();
    // const [selectedCoord, setSelectedCoord] = useState();
    // const [previousFeature, setPreviousFeature] = useState();
    const [currentFeature, setCurrentFeature] = useState();


    // pull refs
    const mapElement = useRef()
    const overlayElement = useRef()
    const popUpContainerElement = useRef()
    const popUpContentElement = useRef()
    const popUpCloserElement = useRef()
    // const mapElement = useRef()

    // create state ref that can be accessed in OpenLayers onclick callback function
    //  https://stackoverflow.com/a/60643670
    const mapRef = useRef()
    mapRef.current = map

    const image = new CircleStyle({
        radius: 5,
        fill: null,
        stroke: new Stroke({ color: 'red', width: 1 }),
    });


    const styleFunction = function (feature) {

        // if (feature.get('structures').trim() === "")
        //     return;
        var isCurrent = false;
        var hasAsset = false;

        if (props.currentPhotoName === feature.get('imageName').trim()) {
            isCurrent = true;
            setCurrentFeature(feature)
        }


        if (feature.get('structures').trim() !== "") {
            hasAsset = true;
        }

        var styles = {
            'Point': new Style({
                stroke: new Stroke({
                    color: 'red',
                    width: 2,
                }),
                fill: new Fill({
                    color: 'rgba(255,0,0,0.2)',
                }),
            }),
            'LineString': new Style({
                stroke: new Stroke({
                    color: 'green',
                    width: 1,
                }),
            }),
            'MultiLineString': new Style({
                stroke: new Stroke({
                    color: 'green',
                    width: 1,
                }),
            }),
            'MultiPoint': new Style({
                image: image,
            }),
            'MultiPolygon': new Style({
                stroke: new Stroke({
                    color: 'yellow',
                    width: 1,
                }),
                fill: new Fill({
                    color: 'rgba(255, 255, 0, 0.1)',
                }),
            }),
            'Polygon': new Style({
                stroke: new Stroke({
                    color: isCurrent ? 'blue' : (hasAsset) ? 'blue' : 'black',
                    lineDash: [4],
                    width: 1,
                }),
                // geometry: feature.getGeometry().getInteriorPoint(),
                // image: new Icon({
                //     size: [50, 50],
                //     src: feature.get('ImageURL'),
                //     anchor: [0.75, 0.5],
                //     rotateWithView: true
                // })
                fill: new Fill({
                    color: isCurrent ? 'rgba(0, 0, 255, 0.5)' : 'rgba(0, 0, 0, 0.1)',
                }),
            }),
            'GeometryCollection': new Style({
                stroke: new Stroke({
                    color: 'magenta',
                    width: 2,
                }),
                fill: new Fill({
                    color: 'magenta',
                }),
                image: new CircleStyle({
                    radius: 10,
                    fill: null,
                    stroke: new Stroke({
                        color: 'magenta',
                    }),
                }),
            }),
            'Circle': new Style({
                stroke: new Stroke({
                    color: 'red',
                    width: 2,
                }),
                fill: new Fill({
                    color: 'rgba(255,0,0,0.2)',
                }),
            }),
        };
        return styles[feature.getGeometry().getType()];
    };

    // initialize map on first render - logic formerly put into componentDidMount
    useEffect(() => {
        // setPopUpContainer(document.getElementById('popup'));
        // create and add vector source layer
        // const initalFeaturesLayer = new VectorLayer({
        //     source: new VectorSource(),

        // })

        const vectorSource = new VectorSource({
            features: new GeoJSON().readFeatures(props.features, {
                featureProjection: 'EPSG:3857'
            }),
        });

        const vectorLayer = new VectorLayer({
            name: "geoData",
            source: vectorSource,
            style: styleFunction,
        });
        var center = props.center.split(',')

        // create map
        const initialMap = new Map({
            target: mapElement.current,
            layers: [
                new TileLayer({
                    source: new XYZ({
                        url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',

                    })
                }),
                vectorLayer,
            ],
            view: new View({
                projection: 'EPSG:3857',
                center: transform([center[1], center[0]], 'EPSG:4326', 'EPSG:3857'),
                zoom: props.zoom
            }),
            controls: []
        })

        overlayElement.current = new Overlay({
            id: "popup",
            element: popUpContainerElement.current,
            autoPan: true,
            offset: [0, 0]
        })

        initialMap.addOverlay(overlayElement.current);

        // set map onclick handler
        initialMap.on('click', handleMapClick)

        initialMap.on('pointermove', function (e) {
            if (e.dragging) return;

            var pixel = e.map.getEventPixel(e.originalEvent);
            var hit = e.map.hasFeatureAtPixel(pixel);

            e.map.getTarget().style.cursor = hit ? 'pointer' : '';
        });

        popUpCloserElement.current.onclick = function (event) {
            overlayElement.current.setPosition(undefined);
            // event.stopPropagation()
            return false;
        };

        // save map and vector layer references to state
        setMap(initialMap)
        // setFeaturesLayer(initalFeaturesLayer)

    }, [])



    useEffect(() => {
        if (map) {
            setNavigationPhotos();
        }
    }, [currentFeature])


    useEffect(() => {
        if (map) {
            setTimeout(function () {
                console.log("Map.Render")
                map.render();
            }, 500);

        }
    }, [props.updateKey])

    // update map if features prop changes - logic formerly put into componentDidUpdate
    useEffect(() => {
        if (map) {
            map.getLayers().forEach(function (layer) {
                if (layer.get('name') !== undefined & layer.get('name') === 'geoData') {
                    map.removeLayer(layer);
                }
            });

            const vectorSource = new VectorSource({
                features: new GeoJSON().readFeatures(props.features, {
                    featureProjection: 'EPSG:3857'
                }),
            });

            // vectorSource.addFeature(new Feature(new Circle([5e6, 7e6], 1e6)));
            const vectorLayer = new VectorLayer({
                name: "geoData",
                source: vectorSource,
                style: styleFunction,
            });
            map.addLayer(vectorLayer);

            setNavigationPhotos();
        }



    }, [props.currentPhotoName])


    // update map if features prop changes - logic formerly put into componentDidUpdate
    useEffect(() => {
        if (map) {
            map.getLayers().forEach(function (layer) {
                if (layer.get('name') !== undefined & layer.get('name') === 'geoData') {
                    map.removeLayer(layer);
                }
            });

            const vectorSource = new VectorSource({
                features: new GeoJSON().readFeatures(props.features, {
                    featureProjection: 'EPSG:3857'
                }),
            });

            // vectorSource.addFeature(new Feature(new Circle([5e6, 7e6], 1e6)));
            const vectorLayer = new VectorLayer({
                name: "geoData",
                source: vectorSource,
                style: styleFunction,
            });
            map.addLayer(vectorLayer);
        }

    }, [props.features])

    useEffect(() => {
        if (map) {
            var center = props.center.split(',')
            map.getView().setCenter(transform([center[1], center[0]], 'EPSG:4326', 'EPSG:3857'));
        }

    }, [props.center])



    const handleMapClick = (evt) => {
        // var overlay = evt.map.getOverlayById("popup");

        var feature = evt.map.forEachFeatureAtPixel(evt.pixel,
            function (feature, layer) {
                return feature;
            });

        if (feature) {
            props.onOverlayClick(feature.get('imageName'), feature.get('projectId'));
            // var geometry = feature.getGeometry();
            // var coord = geometry.getCoordinates();
            // var assetString = feature.get('imageName') !== "" ? `<p class="mt-0 pt-0">Asset: ${feature.get('structures').replace(';', ',')}</p>` : "";

            // var content = `<div class="popup-thumb"><img id="popup-img" class="pointer" data-name="${feature.get('imageName')}" src="${feature.get('imageURL')}" height="75px" width="75px" /></div>
            //  <div class="popup-content">
            //  <div>
            //     <p class="popup-title">${feature.get('imageName')}</p>
            //     ${assetString}
            //     </div>
            // </div>`;

            // var imageElement = document.getElementById('popup-img');
            // ImageService.getImageThumbnailByLocation(feature.get('imageURL')).then(response => {
            //     imageElement.src = response;
            // })
            // popUpContentElement.current.innerHTML = content;
            // overlayElement.current.setPosition([coord[0][2][0], coord[0][2][1]]);

            // var imageElement = document.getElementById('popup-img');
            // imageElement.onclick = (event) => {
            //     props.onOverlayClick(event.target.dataset.name.trim(), event.target.dataset.projectId);
            //     return;
            // };
        }
    }

    const setNavigationPhotos = () => {
        map.getLayers().forEach(function (layer) {
            if (layer.get('name') !== undefined & layer.get('name') === 'geoData') {
                var source = layer.getSource();
                var featureList = source.uidIndex_;
                var featureKeys = Object.keys(featureList);
                for (var i = 0; i < featureKeys.length; i++) {

                    if (props.currentPhotoName === featureList[featureKeys[i]].get('imageName').trim()) {
                        if (i < featureKeys.length - 1) {
                            props.nextPhotoHandler(featureList[featureKeys[i + 1]].get('imageName').trim());
                        } else {
                            props.nextPhotoHandler(null);
                        }

                        if (i > 0) {
                            props.previousPhotoHandler(featureList[featureKeys[i - 1]].get('imageName').trim());
                        } else {
                            props.previousPhotoHandler(null);
                        }
                        break;
                    }
                }

            }
        });
    }

    // render component
    return (
        <>
            <div ref={mapElement} className="map-container">
            </div>

            <div>
                <div id="popup" ref={popUpContainerElement} className="ol-popup">
                    <div id="popup-closer" ref={popUpCloserElement} className="ol-popup-closer"></div>
                    <div id="popup-content" ref={popUpContentElement}>

                    </div>
                </div>
            </div>
        </>
    )

}

export default MapWrapper