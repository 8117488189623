const local = {
    env: 'local',
    smsession: "",
    apiUrl: 'https://localhost:5001/',
    subtitle: "Local",
    auth: {
        authority: 'https://login.microsoftonline.com/270992cd-9003-4971-84de-d1640c0bffc5',
        clientId: '3ca27d5e-1a6c-4bdb-989c-cb6a71c5a7ec',
        scopes: ['api://heliview-dev/all-read']
    },
};

const dev = {
    env: 'dev',
    smsession: "",
    apiUrl: 'https://tva-azr-eastus-as-heliview-dev.azr1.tva.gov/',
    subtitle: "Dev",
    auth: {
        authority: 'https://login.microsoftonline.com/270992cd-9003-4971-84de-d1640c0bffc5',
        clientId: '3ca27d5e-1a6c-4bdb-989c-cb6a71c5a7ec',
        scopes: ['api://heliview-dev/all-read']
    },
};

const acc = {
    env: 'acc',
    smsession: "",
    apiUrl: 'https://tva-azr-eastus-as-heliview-acc.azr1.tva.gov/',
    subtitle: "Acc",
    auth: {
        authority: 'https://login.microsoftonline.com/270992cd-9003-4971-84de-d1640c0bffc5',
        clientId: 'aede0be5-f0d4-428d-8753-e28b363edd4f',
        scopes: ['api://heliview-acc/all-read']
    },
};

const prod = {
    env: 'prod',
    smsession: "",
    apiUrl: 'https://heliviewer.tva.gov/',
    subtitle: "",
    auth: {
        authority: 'https://login.microsoftonline.com/270992cd-9003-4971-84de-d1640c0bffc5',
        clientId: '8b091241-3a79-4886-8762-213159e056dd',
        scopes: ['api://heliview/all-read']
    },
};

const config = process.env.REACT_APP_STAGE === 'dev' ? dev :
                process.env.REACT_APP_STAGE === 'acc' ? acc :
                process.env.REACT_APP_STAGE === 'prod' ? prod :
                process.env.REACT_APP_STAGE === 'local' ? local :
                local;

export default {
    title: "HeliViewer",
    buildNumber: "v1.0",
    ...config
};
