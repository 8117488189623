import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Navbar,
    Form,
    Row,
    Col,
    Button
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { setSearchTerm, setSearchType, search, setSelectedSort, filterSearch } from '../../../redux/actions/actions'
import SuggestTextBox from "../../shared/auto-suggest/auto-suggest";
import SuggestionService from '../../../services/suggestion-service';
import { useGeolocated } from "react-geolocated";

function SearchNavbar(props) {
    //Redux State
    const searchTerm = useSelector(state => state.searchReducer.searchTerm);
    const sortOptions = useSelector(state => state.searchReducer.sortOptions);
    const matchesFound = useSelector(state => state.searchReducer.matchesFound);
    const timeTakenMs = useSelector(state => state.searchReducer.timeTakenMs);
    const selectedSort = useSelector(state => state.searchReducer.selectedSort);

    const searchResultsLoading = useSelector(state => state.searchReducer.searchResultsLoading);

    const dispatch = useDispatch();


    //State
    // const [hasError, setHasError] = useState(false);
    const [searchValue, setSearchValue] = useState(searchTerm);
    const [localSearchType, setLocalSearchType] = useState("");


    const { coords, isGeolocationAvailable, isGeolocationEnabled } =
        useGeolocated({
            positionOptions: {
                enableHighAccuracy: false,
            },
            userDecisionTimeout: 5000,
        });


    useEffect(() => {
        setSearchValue(searchTerm);
    }, [searchTerm])

    /**
    *Updates the state of the search value on type
    */
    // const updateSearchValue = (event) => {
    //     setSearchValue(event.target.value);
    // }


    /**
     * Called the sort the search results
     * @param {any} event
     */
    const resultSortHandler = (event) => {
        //Change the sort state then search
        dispatch(setSelectedSort(event.target.value));
        dispatch(filterSearch());
    }


    /**
     * Called when a button is pressed
     * @param {any} event
     */
    const handleKeyPress = (event) => {
        //Search when enter is pressed
        if (event.key === 'Enter') {
            searchClickHandler();
        }
    }

    /**
     *
     */
    const searchClickHandler = () => {
        //Create a new search by clicking the search button
        //This resets the filters and filter selections
        //Cant search with an empty string
        if (searchValue !== "") {
            // setHasError(false);
            dispatch(setSearchTerm(searchValue));
            dispatch(setSearchType(localSearchType));

            dispatch(search());
            // history.push({
            //     pathname: '/search',
            //     search: `?query=${searchValue}` +
            //         (localSearchType === "" ? "" : `?type=${localSearchType}`)
            // });
        } else {
            // setHasError(true);
        }
    }

    /**
* Called to Perform a search
* */
    const locationSearch = () => {
        var location = coords.latitude + "," + coords.longitude
        dispatch(setSearchTerm(location));
        dispatch(setSearchType(localSearchType));
        dispatch(search());
    }
    /**
     * Render
     */
    return (
        <Navbar className="subnav pt-0 mt-0 mb-2 ml-2 justify-content-between">
            <div className="results-search">
                <div className="input-group search-bar">
                    <SuggestTextBox
                        multiSection={true}
                        placeholder="Search"
                        suggestionCount={10}
                        value={searchValue}
                        setType={setLocalSearchType}
                        setValue={setSearchValue}
                        onSelect={props.search}
                        handleKeyPress={handleKeyPress}
                        getSuggestions={SuggestionService.getSearchSuggestions} />
                    <div class="input-group-append">
                        {isGeolocationAvailable && isGeolocationEnabled ?
                            <Button variant="light" className='location-button shadow-none' onClick={locationSearch}><FontAwesomeIcon className="text-secondary" icon={faMapMarkerAlt} /></Button>
                            : <></>}
                        <Button variant="light" onClick={search}><FontAwesomeIcon className="text-secondary" icon={faSearch} /></Button>
                    </div>
                </div>

                {!searchResultsLoading
                    ?
                    <Form.Text className="ml-1">
                        {`${matchesFound.toLocaleString()} Results for ${searchTerm} (${(timeTakenMs / 1000).toFixed(3).toString()} seconds)`}
                    </Form.Text>
                    : null}
            </div>
            {!searchResultsLoading ?
                <Form inline className="mr-sm-2">
                    <Form.Group size="sm">
                        <Form.Control as="select" size="sm" onChange={resultSortHandler} value={selectedSort}>
                            {sortOptions.map((sortOption, key) => {
                                return <option key={key} value={sortOption.name}>{sortOption.display}</option>;
                            })}
                        </Form.Control>
                    </Form.Group>
                </Form>
                : null}
        </Navbar >
    );
}

export default SearchNavbar
