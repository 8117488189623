import React from "react";
// import _ from "underscore"
import {
    Row,
    Spinner
} from 'react-bootstrap';
import FilterSection from "./filter-section"


function FilterContainer(props) {

    /**
     * Render
     */
    return (
        <div>
            {

          
                props.filters && Object.keys(props.filters).length > 0 && !props.isLoading ?
                    Object.keys(props.filters).map((key, index) => (
                        
                        props.parent ?
                            (props.filters[key].parent === props.parent)
                                ?
                                <FilterSection
                                    key={`${props.filters[key].parent}_${props.filters[key].name}_${index}`}
                                    filterContainer={props.filters[key]} />
                                : null
                            :
                            (props.filters[key].parent === key)
                                ?
                                <FilterSection
                                    key={`${props.filters[key].parent}_${props.filters[key].name}_${index}`}
                                    filterContainer={props.filters[key]} />
                                : null
                    ))
                    :
                    <>
                        <Row className="justify-content-center mt-3">
                            <Spinner animation="border" variant="secondary" />
                        </Row>
                    </>
            }
        </div>
    );
}
export default FilterContainer;
