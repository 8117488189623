import './filter-section.css'
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import _ from "underscore";
// import DatePicker from 'react-date-picker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faAngleDown, faAngleUp, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import {
    Row,
    Col,
    Form,
    Container,
    Card,
    Collapse,
    Spinner,
    InputGroup
} from 'react-bootstrap';
import {
    toggleFilter,
    getMoreFilters,
    updateFilterData,
    setIsDataLoaded,
    updateFilterIsCollapsed,
    getChildFilters
} from '../../../redux/actions/actions'
// import DatePicker from "react-datepicker";



function FilterSection(props) {

    const dispatch = useDispatch();

    //Create State hooks
    const [searchValue, setSearchValue] = useState("");



    /**
     * 
     */
    useEffect(function () {
        updateDisplayFilters();
    }, [searchValue]);

    /**
     * Show and hide the filters
     */
    const toggleCollapse = () => {
        if (!props.filterContainer.isDataLoaded) {
            dispatch(setIsDataLoaded(true, props.filterContainer.key));
            showMore();
        }
        dispatch(updateFilterIsCollapsed(!props.filterContainer.isCollapsed, props.filterContainer.key));
    }

    /**
     * Updated the set when user types 
     * @param {*} event - Input 
     */
    const updateSearchValue = async (event) => {
        if (props.filterContainer.isCollapsed)
            toggleCollapse();
        setSearchValue(event.target.value);
    }


    /**
     * Show Filters based on the display count and 
     * user input
     */
    const updateDisplayFilters = () => {
        var newFilterState = [];
        //Iterate through the sorted filters
        if (props.filterContainer.filters) {
            if (props.filterContainer.type === "multi-list") {
                _.each(props.filterContainer.filters, function (filter) {
                    //Rules for detemining if a filter should be displayed
                    if ((filter.name.toLowerCase().startsWith(searchValue.toLowerCase())) || filter.isChecked) {
                        filter.isVisible = true;
                    } else {
                        filter.isVisible = false;
                    }
                    newFilterState.push(filter);
                });
                dispatch(updateFilterData(newFilterState, props.filterContainer.key, props.filterContainer.parent));
            } else {
                if (props.filterContainer.filters.length > 0) {
                }
            }
        }

    }

    /**
     *
     */
    const showMore = () => {
        dispatch(getMoreFilters(props.filterContainer));
    }


    /**
     * Called when a checkbox is clicked
     * @param {*} filter - The Object being clicked(Checked)
     */
    const onCheckboxClicked = (filter) => {
        setSearchValue("");
        dispatch(toggleFilter(filter, props.filterContainer.key, props.filterContainer.parent));
    }

    const buildMultiSelect = () => {
        return (
            <>
                {props.filterContainer.filters.length > 0
                    ?
                    <>
                        <div style={{ paddingBottom: '12px' }}>
                            {
                                props.filterContainer.filters && props.filterContainer.filters.map(function (filter, index) {
                                    if (filter.isChecked) {

                                        return <Row key={index} >
                                            <Form.Check
                                                key={`Checked_${props.filterContainer.parent}_${props.filterContainer.key}_${index}`}
                                                custom
                                                className={(filter.isAvailable === 1 ? "" : "is-not-available ") + "ml-2 pointer accent-hover filter-item"}
                                                type="checkbox"
                                                label={props.filterContainer.hasCounts ? filter.name + " (" + filter.count + ")" : filter.name}
                                                // label={filter.name}
                                                id={`custom-${props.filterContainer.parent}_${filter.name}_${props.filterContainer.key}_${index}`}
                                                value={filter.name}
                                                checked={filter.isChecked}
                                                onChange={() => onCheckboxClicked(filter)} />
                                            {props.filterContainer.action !== null
                                                ?
                                                <FontAwesomeIcon
                                                    size="xs"
                                                    className="pointer filter-label-icon ml-auto"
                                                    icon={faChevronRight}
                                                    onClick={() => dispatch(getChildFilters(props.filterContainer.action, filter.name))}
                                                />
                                                : <></>
                                            }
                                        </Row>
                                    }
                                })
                            }
                        </div>

                        <div>
                            {
                                props.filterContainer.filters && props.filterContainer.filters.map(function (filter, index) {
                                    if (!filter.isChecked && filter.isVisible)
                                        return <Row key={index}><Form.Check
                                            key={`${props.filterContainer.parent}_${props.filterContainer.key}_${index}`}
                                            custom
                                            className={(filter.isAvailable === 1 ? "" : "is-not-available ") + "ml-2 pointer accent-hover filter-item"}
                                            type="checkbox"
                                            label={props.filterContainer.hasCounts ? filter.name + " (" + filter.count + ")" : filter.name}
                                            //label={filter.name}
                                            id={`custom-${props.filterContainer.parent}_${filter.name}_${props.filterContainer.key}_${index}`}
                                            value={filter.name}
                                            checked={filter.isChecked}
                                            onChange={() => onCheckboxClicked(filter)} />
                                        </Row>

                                })
                            }
                        </div>
                        {
                            props.filterContainer.isLazyLoad ?
                                <Form.Text className="text-muted  ml-5 pointer" onClick={() => showMore()}>Show More</Form.Text>
                                : null
                        }
                    </>
                    :
                    <Row className="justify-content-center mt-3">
                        <Form.Text className="text-muted">No Filters Found</Form.Text>
                    </Row>
                }
            </>

        );
    }

    const buildDateSelect = () => {

    //     if (props.filterContainer.filters.length > 0)
    //         var dateValue = parseDate(props.filterContainer.filters[0].name);
    //     else
    //         var dateValue = null;


    //     return (<>

    //         <DatePicker className="form-control"
    //             showLeadingZeros={true}
    //             format={'M/dd/yyyy'}
    //             value={dateValue}
    //             onChange={(date) => onDateSelected(date)}
    //         />
    //     </>)
    // }

    // const parseDate = (dateString) => {
    //     var y = dateString.substr(0, 4),
    //         m = dateString.substr(4, 2) - 1,
    //         d = dateString.substr(6, 2);
    //     return new Date(y, m, d);
    }

    
    /**
     * Render
     */
    return (

        <Container>
            <Card className="border-0 filter-container">
                <Card.Header className={props.filterContainer.isSearchable ? "mb-2 pb-0 mt-2 ml-0 mr-0 border-0 filter-header" : "mb-2 pb-0 mt-2 filter-header"}>
                    <Row>
                        <Col xs="1" className="ml-0 pl-0 pr-3">
                            <div className={props.filterContainer.isSearchable ? "collapse-icon" : ""}>
                                <FontAwesomeIcon className="pointer"
                                    icon={!props.filterContainer.isCollapsed ? faAngleUp : faAngleDown}
                                    onClick={() => toggleCollapse()} />
                            </div>
                        </Col>
                        <Col className="ml-0 mr-0 pl-0 pr-0">
                            {
                                props.filterContainer.isSearchable ?
                                    <InputGroup className="ml-0 mr-0 pl-0 pr-0">
                                        <Form.Control className="ml-0 mr-0 pl-0 pr-0 filter-input"
                                            type="text"
                                            placeholder={props.filterContainer.title}
                                            size="sm"
                                            value={searchValue}
                                            onChange={updateSearchValue} />
                                        <InputGroup.Append>
                                            <div><FontAwesomeIcon icon={faSearch} /></div>
                                        </InputGroup.Append>
                                    </InputGroup>
                                    :
                                    <p className="float-left filter-header-text">{props.filterContainer.title}</p>
                            }
                        </Col>
                    </Row>
                </Card.Header>
                <Collapse in={!props.filterContainer.isCollapsed}>
                    <div>
                        <Card.Body className="mt-1 pt-0">
                            <form className="">
                                {props.filterContainer.isLoading
                                    ?
                                    <Row className="justify-content-center mt-3">
                                        <Spinner animation="border" variant="secondary" />
                                    </Row>
                                    :
                                    <>
                                        {props.filterContainer.type === "multi-list" ? buildMultiSelect() : buildDateSelect()}
                                    </>
                                }
                            </form>
                        </Card.Body>
                    </div>
                </Collapse>
            </Card>
        </Container >
    );
}

export default FilterSection;

