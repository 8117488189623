import './landing-page.css';
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
    Container,
    CardDeck,
    Row,
    Col,
    Button,
} from 'react-bootstrap';
// import Autosuggest from 'react-autosuggest';
import SuggestTextBox from '../../shared/auto-suggest/auto-suggest';
import SuggestionService from '../../../services/suggestion-service';
import {
    clearSearchResults,
    setSearchTerm,
    setSearchType,
    getLatestMissions
} from '../../../redux/actions/actions'
import logoSVG from '../../../assets/images/Capture.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import MissionCard from '../../shared/mission-card/mission-card';
import CardSlider from '../../shared/card-slider/card-slider';
import video from '../../../assets/video/cut.mp4';
import Footer from '../../shared/footer/footer';
import MissionCardLoading from '../../shared/loaders/mission-card-loading';
import { useGeolocated } from "react-geolocated";



// export class LandingPage extends Component {
function LandingPage(props) {

    // State
    const [searchValue, setSearchValue] = useState("");
    const [localSearchType, setLocalSearchType] = useState("");
    const [latestMissions, setLatestMissions] = useState(null);

    const { coords, isGeolocationAvailable, isGeolocationEnabled } =
        useGeolocated({
            positionOptions: {
                enableHighAccuracy: false,
            },
            userDecisionTimeout: 5000,
        });


    // const [hasError, setHasError] = useState(false);
    // const [errorMessage, setErrorMessage] = useState("");
    const dispatch = useDispatch();


    useEffect(() => {
        setupLandingPage();
    }, [])


    const setupLandingPage = () => {
        dispatch(clearSearchResults());
        dispatch(getLatestMissions())
            .then((response) => {
                if (response !== null) {
                    setLatestMissions(response)
                }
            });
    }

    /**
     * Called to Perform a search
     * */
    const search = () => {
        dispatch(setSearchTerm(searchValue));
        dispatch(setSearchType(localSearchType));
        if (searchValue !== "") {
            props.history.push({
                pathname: '/search',
                query: searchValue
            });
        } else {
            // setHasError(true);
            // setErrorMessage("Enter a Search Value")
        }
    }

    /**
 * Called to Perform a search
 * */
    const locationSearch = () => {
        var location = coords.latitude + "," + coords.longitude
        dispatch(setSearchTerm(location));
        dispatch(setSearchType(localSearchType));
        if (location !== "") {
            props.history.push({
                pathname: '/search',
                query: location
            });
        } else {
            // setHasError(true);
            // setErrorMessage("Enter a Search Value")
        }
    }

    /**
     * Called when the user presses a key
     * @param {any} event
     */
    const handleKeyPress = (event) => {
        //When enter is hit, search
        if (event.key === 'Enter') {
            search();
        }
    }

    /**
 * Called when the user presses a key
 * @param {any} event
 */
    const handleItemSelect = () => {
        search();
    }

    const onProjectClick = (value, type) => {
        dispatch(setSearchTerm(value));
        dispatch(setSearchType(type));
        if (value !== "") {
            props.history.push({
                pathname: '/search',
                query: value
            });
        } else {
            // setHasError(true);
            // setErrorMessage("Enter a Search Value")
        }
    }

    /**
    * Render
    * */
    return (
        <>
            <div className="body">
                <div className='search-body'>
                    <video autoPlay={true} loop={true} id="video-background-7608" muted="muted" plays-inline="">
                        <source src={video} type="video/mp4" />
                    </video>
                    <div className='v-overlay'></div>

                    <div className="caption">
                        <Row className="logo-container align-items-center">
                            <Col>
                                <img height="250px" width="550px" src={logoSVG} alt="" className="mx-auto my-auto"></img>
                            </Col>
                        </Row>

                        <Col>
                            <div className="input-group search-bar justify-content-center">
                                <SuggestTextBox
                                    className="text-center form-control"
                                    multiSection={true}
                                    placeholder="Search"
                                    suggestionCount={7}
                                    value={searchValue}
                                    setValue={setSearchValue}
                                    setType={setLocalSearchType}
                                    handleKeyPress={handleKeyPress}
                                    onItemSelect={handleItemSelect}
                                    getSuggestions={SuggestionService.getSearchSuggestions} />
                                <div class="input-group-append">
                                    {isGeolocationAvailable && isGeolocationEnabled ?
                                        <Button variant="light" className='location-button shadow-none' onClick={locationSearch}><FontAwesomeIcon className="text-secondary" icon={faMapMarkerAlt} /></Button>
                                        : <></>}
                                    <Button variant="light" onClick={search}><FontAwesomeIcon className="text-secondary" icon={faSearch} /></Button>
                                </div>
                            </div>

                        </Col>


                        {/* <Row className="search-bar ">
                                <div className='text-center d-inline'>
                                    <Col className="mr-0 pr-0">
                                        <SuggestTextBox
                                            className="text-center"
                                            multiSection={true}
                                            placeholder="Search"
                                            suggestionCount={7}
                                            value={searchValue}
                                            setValue={setSearchValue}
                                            setType={setLocalSearchType}
                                            handleKeyPress={handleKeyPress}
                                            onItemSelect={handleItemSelect}
                                            getSuggestions={SuggestionService.getSearchSuggestions} />
                                    </Col>
                                    <Col className="m-0 p-0 ">
                                        {isGeolocationAvailable && isGeolocationEnabled ?
                                            <Button variant="light" className='location-button shadow-none' onClick={locationSearch}><FontAwesomeIcon className="text-secondary" icon={faMapMarkerAlt} /></Button>
                                            : <></>}
                                        <Button variant="light" onClick={search}><FontAwesomeIcon className="text-secondary" icon={faSearch} /></Button>
                                    </Col>
                                </div>
                            </Row> */}

                    </div>

                </div>

                <Container className='landing-section'>
                    {/* <hr className="featurette-divider" /> */}
                    <Row>
                        <h4>All Projects</h4>
                    </Row>
                    <hr />
                    <Row className='mb-5'>
                        {latestMissions ?
                            <CardDeck>
                                {
                                    latestMissions.length > 0 && latestMissions.map((mission, index) => {
                                        return (<Col lg={3} md={4} xs={12} key={index}>
                                            <MissionCard className="mt-2" onClick={() => onProjectClick(mission.projectId, 'PROJECTID')} key={index} mission={mission}></MissionCard>
                                        </Col>
                                        )
                                    })
                                }
                            </CardDeck>
                            : <div className="float-left"><MissionCardLoading className='mt-5 ml-5' /></div>}


                    </Row>
                    {/* <hr className="featurette-divider" /> */}
                    {/* <Row>
                    <Col md={7}>
                        <h2>Custom Build Search Engine <span className="text-muted"></span></h2>
                        <p className="lead">Use the custom built search engine to find the exact image you need.</p>
                        <p className="lead">Current supported fields.</p>
                        <ol>
                            <li>Mission Id</li>
                            <li>Project Id</li>
                            <li>Project Name</li>
                            <li>Image Name</li>
                            <li>Structures</li>
                            <li>UNID</li>
                            <li>Project Work Order</li>
                            <li>Tranmission Line #</li>
                            <li>Longitude and Latitude</li>
                        </ol>
                    </Col>
                    <Col md={5}>
                        <img className="featurette-image img-fluid mx-auto" data-src="holder.js/500x500/auto" height="400px" width="400px" src="data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22500%22%20height%3D%22500%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20500%20500%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_1802421ec15%20text%20%7B%20fill%3A%23AAAAAA%3Bfont-weight%3Abold%3Bfont-family%3AArial%2C%20Helvetica%2C%20Open%20Sans%2C%20sans-serif%2C%20monospace%3Bfont-size%3A25pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_1802421ec15%22%3E%3Crect%20width%3D%22500%22%20height%3D%22500%22%20fill%3D%22%23EEEEEE%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22185.1171875%22%20y%3D%22261.1%22%3E500x500%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E" data-holder-rendered="true" />
                    </Col>
                </Row>
                <hr className="featurette-divider" />
                <Row>
                    <Col md={5}>
                        <img className="featurette-image img-fluid mx-auto" data-src="holder.js/500x500/auto" height="400px" width="400px" src="data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22500%22%20height%3D%22500%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20500%20500%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_1802421ec15%20text%20%7B%20fill%3A%23AAAAAA%3Bfont-weight%3Abold%3Bfont-family%3AArial%2C%20Helvetica%2C%20Open%20Sans%2C%20sans-serif%2C%20monospace%3Bfont-size%3A25pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_1802421ec15%22%3E%3Crect%20width%3D%22500%22%20height%3D%22500%22%20fill%3D%22%23EEEEEE%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22185.1171875%22%20y%3D%22261.1%22%3E500x500%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E" data-holder-rendered="true" />
                    </Col>
                    <Col md={7}>
                        <h2>Reduced Load Times <span class="text-muted"></span></h2>
                        <p className="lead">Using the latest technology to view high-resolution zoomable images with near instant load times. </p>
                    </Col>
                </Row>
                <hr className="featurette-divider" /> */}
                </Container>


                {/* <Footer isFixed={false} ></Footer> */}
            </div >
        </>
    );
}

export default LandingPage;
