
import React from 'react'
import ContentLoader from 'react-content-loader'

const MissionCardLoading = props => (
  <ContentLoader
    width={1100}
    height={370}
    viewBox="0 0 1100 370"
    backgroundColor="#404040" 
    foregroundColor="#303030"
    {...props}
  >
    <rect x="10" y="10" rx="16" ry="0" width="250" height="250" />
    <rect x="45" y="270" rx="3" ry="3" width="170" height="20" />
    <rect x="60" y="295" rx="3" ry="3" width="130" height="15" />

    <rect x="410" y="10" rx="16" ry="0" width="250" height="250" />
    <rect x="445" y="270" rx="3" ry="3" width="170" height="20" />
    <rect x="460" y="295" rx="3" ry="3" width="130" height="15" />

    <rect x="810" y="10" rx="16" ry="0" width="250" height="250" />
    <rect x="845" y="270" rx="3" ry="3" width="170" height="20" />
    <rect x="860" y="295" rx="3" ry="3" width="130" height="15" />


  </ContentLoader>
)



export default MissionCardLoading