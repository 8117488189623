import _ from "underscore";
import ImageService from '../../services/image-service';


/**
 * Get image properties from service
 * @param {*} imageName - name of the image
 * @returns Image Properties
 */
export const getImagePropertiesByName = (imageName, projectId) => {
    return () => {
        //Call to image services
        return ImageService.getImagePropertiesByName(imageName, projectId)
            .then((response) => {
                return response;
            })
    };
}

export const getImageAnnotation = (projectId, imageName) => {
    return () => {
        //Call to image services
        return ImageService.getImageAnnotation(projectId, imageName)
            .then((response) => {
                return response;
            })
    };
}

export const addImageAnnotation = (annotation, projectId, imageName) => {
    return () => {

        var comment = _.findWhere(annotation['body'], {purpose: "comment"});
        var label = _.findWhere(annotation['body'], {purpose: "label"});
        var labelAttribute = _.findWhere(annotation['body'], {purpose: "labelAttribute"});


        var newAnnotation = {
            comment: (comment === null || typeof comment === "undefined")  ? "" : comment.value,
            label: (label === null || typeof label === "undefined")  ? "" : label.value,
            labelAttribute: (labelAttribute === null || typeof labelAttribute === "undefined")  ? "" : labelAttribute.value,
            boundingBox: annotation['target']['selector']['value'],
            projectId:projectId,
            imageName:imageName
        }
        console.log(newAnnotation);
        //Call to image services
        return ImageService.addImageAnnotation(newAnnotation)
            .then((response) => {
                return response;
            })
    };
}

export const updateImageAnnotation = (annotation, projectId, imageName) => {
    return () => {

        var comment = _.findWhere(annotation['body'], {purpose: "comment"});
        var label = _.findWhere(annotation['body'], {purpose: "label"});
        var labelAttribute = _.findWhere(annotation['body'], {purpose: "labelAttribute"});


        var newAnnotation = {
            id: annotation['id'],
            comment: (comment === null || typeof comment === "undefined")  ? "" : comment.value,
            label: (label === null || typeof label === "undefined")  ? "" : label.value,
            labelAttribute: (labelAttribute === null || typeof labelAttribute === "undefined")  ? "" : labelAttribute.value,
            boundingBox: annotation['target']['selector']['value'],
            projectId:projectId,
            imageName:imageName
        }
        console.log(newAnnotation);
        //Call to image services
        return ImageService.updateImageAnnotation(newAnnotation)
            .then((response) => {
                return response;
            })
    };
}


export const deleteImageAnnotation = (annotationId) => {
    return () => {
        //Call to image services
        return ImageService.deleteImageAnnotation(annotationId)
            .then((response) => {
                return response;
            })
    };
}

/**
 * Get image properties from service
 * @param {*} imageName - name of the image
 * @param {*} missionName - name of the mission
 * @param {*} assetName - name of the asset
 * @returns Image Properties
 */
export const getAssetImageByMission = (missionName, assetName) => {
    return () => {
        //Call to image services
        return ImageService.getAssetImageByMission(missionName, assetName)
            .then((response) => {
                return response;
            })
    };
}

/**
 * Get image properties from service
 * @param {*} imageName - name of the image
 * @returns Image Properties
 */
export const getImageThumbnailByLocation = (location, imageName) => {
    return () => {
        //Call to image services
        return ImageService.getImageThumbnailByLocation(location, imageName)
            .then((response) => {
                return response;
            })
    };
}

export const getDocumentByLocation = (projectId, cameraDirection, lat, lon) => {
    return (dispatch, getState) => {
        return ImageService.getDocumentBylocation(projectId, cameraDirection, lat, lon).then(response => {
            if (response !== null) {
                return response;
            }
        });
    };
}
