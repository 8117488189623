// authProvider.js
import { MsalAuthProvider, LoginType } from 'react-aad-msal';
// import { Logger, LogLevel } from "msal";
import config from '../config';

console.log(window.location)
// Msal Configurations
const msalconfig = {
  auth: {
    authority: config.auth.authority,
    clientId: config.auth.clientId,
    redirectUri: window.location.origin,
    validateAuthority: true,
    navigateToLoginRequestUrl: true
  },
  // system: {
  //   logger: new Logger(
  //     (logLevel, message, containsPii) => {
  //     },
  //     {
  //       level: LogLevel.Verbose,
  //       piiLoggingEnabled: false
  //     }
  //   )
  // },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true
  }
};



// Authentication Parameters
const authenticationParameters = {
  scopes: config.auth.scopes
}

// Options
const options = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: window.location.origin
}

console.log(msalconfig);
export const authProvider = new MsalAuthProvider(msalconfig, authenticationParameters, options)
