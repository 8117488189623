import {
    SET_SHOW_FILTERS,
    SET_IS_DATA_LOADED,
    UPDATE_FILTER_IS_LOADING,
    SET_CHILD_FILTERS_LOADING,
    UPDATE_FILTER_IS_COLLAPSED,
    SHOW_CHILD_FILTERS,
    SET_PARENT_FILTERS,
    APPEND_FILTER,
    RESET_FILTERS,
    UPDATE_FILTER_DATA
} from '../actions/actions';


/*
Create intial state for the Fitlers
Store global filter state here
*/
const filterInitialState = {
    showFilters: true,
    showChildFilters: false,
    isChildFiltersLoading: false,
    loadFilters: false,
    filters: {},
    parentFilterName: ""
};


function filterReducer(state = filterInitialState, action) {
    switch (action.type) {

        case SET_SHOW_FILTERS:
            return {
                ...state,
                showFilters: action.payload
            };
        case SET_IS_DATA_LOADED:
            return Object.assign({}, state, {
                // TODO: Find a cleaner way to do this!
                filters: Object.assign({}, state.filters, {
                    [action.payload.key]: Object.assign({}, state.filters[action.payload.key], {
                        isDataLoaded: action.payload.isLoaded,
                    }),
                })
            });
        case APPEND_FILTER:
            return Object.assign({}, state, {
                // TODO: Find a cleaner way to do this!
                filters: Object.assign({}, state.filters, {
                    [action.payload.key]: Object.assign({}, state.filters[action.payload.key], {
                        filters: state.filters[action.payload.key].filters.concat(action.payload.filters),
                        offset: state.filters[action.payload.key].filters.length + action.payload.filters.length
                    }),
                }),
            });
        case UPDATE_FILTER_DATA:
            return Object.assign({}, state, {
                // TODO: Find a cleaner way to do this!
                filters: Object.assign({}, state.filters, {
                    [action.payload.key]: Object.assign({}, state.filters[action.payload.key], {
                        filters: action.payload.filters,
                        offset: action.payload.filters.length
                    }),
                }),
            });
        case UPDATE_FILTER_IS_LOADING:
            return Object.assign({}, state, {
                // TODO: Find a cleaner way to do this!
                filters: Object.assign({}, state.filters, {
                    [action.payload.key]: Object.assign({}, state.filters[action.payload.key], {
                        isLoading: action.payload.isLoading,
                    }),
                })
            });
        case UPDATE_FILTER_IS_COLLAPSED:
            return Object.assign({}, state, {
                // TODO: Find a cleaner way to do this!
                filters: Object.assign({}, state.filters, {
                    [action.payload.key]: Object.assign({}, state.filters[action.payload.key], {
                        isCollapsed: action.payload.isCollapsed,
                    }),
                })
            });
        case RESET_FILTERS:
            return {
                ...state,
                filters: action.payload
            };
        case SET_CHILD_FILTERS_LOADING:
            return {
                ...state,
                isChildFiltersLoading: action.payload
            };
        case SHOW_CHILD_FILTERS:
            return {
                ...state,
                showChildFilters: action.payload
            };
        case SET_PARENT_FILTERS:
            return {
                ...state,
                parentFilterName: action.payload
            };
        default:
            return state;
    };
}

export default filterReducer;
