import axios from 'axios';
import config from '../config';
import { authProvider } from './auth-service';

axios.defaults.headers.get['Pragma'] = 'no-cache';
axios.defaults.headers.get['Cache-Control'] = 'no-cache, no-store';


/**
 * Service to get Mission Data
 */
export default class MissionService {


    /** 
     * Get Mission Features by Name
     */
    static getMissionFeaturesByName = async (projectId, imageType) => {
        var token = await authProvider.getAccessToken();
        return axios.get(config.apiUrl + `api/mission/features/${projectId}/${imageType}`,
            {
                headers: {
                    Authorization: 'Bearer ' + token.accessToken,
                }
            })
            .then((response) => {
                if (response.status === 200) {
                    return response.data;
                }
                throw response;
            })
            .catch((error) => {
                console.log(error);
                return null;
            })
    };

    static getLatestMissions = async () => {
        var token = await authProvider.getAccessToken();
        return axios.get(config.apiUrl + `api/image/latest/missions`,
            {
                headers: {
                    Authorization: 'Bearer ' + token.accessToken,
                }
            })
            .then((response) => {
                if (response.status === 200) {
                    return response.data;
                }
                throw response;
            })
            .catch((error) => {
                console.log(error);
                return null;
            })
    };

}

