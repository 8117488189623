import _ from "underscore";
import SearchService from '../../services/search-service';
import {
    createFilterContainers,
    resetFilters,
    showChildFilters,
    setParentFilter,
    crossFilter
} from './filter-actions';

export const SET_IS_SEARCH_RESULTS_LOADING = 'SET_IS_SEARCH_RESULTS_LOADING';
export const SET_SEARCH_TERM = 'SET_SEARCH_TERM';
export const SET_SEARCH_TYPE = 'SET_SEARCH_TYPE';
export const SET_SORT_OPTIONS = 'SET_SORT_OPTIONS';
export const SET_SELECTED_SORT = "SET_SELECTED_SORT";
export const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const SET_SEARCH_SUMMARY = 'SET_SEARCH_SUMMARY';
export const SET_CORRECTED_QUERY = 'SET_CORRECTED_QUERY';

export const SET_SCID = 'SET_SCID';


// Seaching
export const search = () => {
    return async (dispatch, getState) => {
        // if (await getState().searchReducer.searchResultsLoading)
        //     return;

        dispatch(showChildFilters(false));
        dispatch(setParentFilter(""));
        dispatch(resetFilters());
        dispatch(setCurrentPage(1));
        dispatch(isSearchResultsLoading(true));
        SearchService.search(getState().searchReducer.searchTerm, getState().searchReducer.searchType, "", "", "").then(response => {
            if (response !== null) {
                dispatch(setSCID(response.scid));
                dispatch(createFilterContainers(response.filters));
                dispatch(setSortOptions(response.sorts))
                dispatch(setSelectedSort(response.sorts[0].name))
                dispatch(setCorrectedQuery(response.correctedQuery));
                dispatch(setSearchSummary(response.totalMatches, response.timeTaken));
                dispatch(setSearchResults(response.first.items));
            }
            dispatch(isSearchResultsLoading(false));
        });
    };
}

// When Filter or sorting 
export const filterSearch = () => {
    return async (dispatch, getState) => {
        // if (await getState().searchReducer.searchResultsLoading)
        //     return;

        dispatch(setCurrentPage(1));
        dispatch(isSearchResultsLoading(true));

        var filterString = ""
        if (await getState().filterReducer.filters) {

            _.each(Object.keys(getState().filterReducer.filters), (key) => {
                var filter = getState().filterReducer.filters[key];

                var selectedFilters = _.filter(filter.filters, (f) => {
                    return f.isChecked;
                });
                var values = _.pluck(selectedFilters, 'name')
                if (values.length > 0)
                    filterString += "&" + filter.name + "=" + values.join(',');


            });
        }
        var sorting = "&sort=" + await getState().searchReducer.selectedSort;
        SearchService.search(getState().searchReducer.searchTerm, getState().searchReducer.searchType, getState().searchReducer.scid, filterString, sorting).then(response => {
            if (response !== null) {
                dispatch(setSCID(response.scid));
                dispatch(crossFilter(response.filters))
                dispatch(setSearchSummary(response.totalMatches, response.timeTaken));
                dispatch(setSearchResults(response.first.items));
            }
            dispatch(isSearchResultsLoading(false));
        });
    };
}

export const clearSearchResults = () => {
    return { type: SET_SEARCH_RESULTS, payload: [] };
}

export const getNextPage = () => {
    return (dispatch, getState) => {
        dispatch(isSearchResultsLoading(true));
        var offset = (getState().searchReducer.currentPage * getState().searchReducer.pageSize) - getState().searchReducer.pageSize
        SearchService.searchNextPage(getState().searchReducer.searchTerm, getState().searchReducer.scid, offset).then(response => {
            if (response !== null) {
                dispatch(setSearchResults(response.items));
                dispatch(isSearchResultsLoading(false));
            }
        });
    };
}

export const getDocumentById = (id) => {
    return (dispatch, getState) => {
        return SearchService.getDocumentById(getState().searchReducer.scid, id).then(response => {
            if (response !== null) {
                return response;
            }
        });
    };
}


export const isSearchResultsLoading = (isLoading) => {
    return { type: SET_IS_SEARCH_RESULTS_LOADING, payload: isLoading };
}

export const setSearchTerm = (searchTerm) => {
    return { type: SET_SEARCH_TERM, payload: searchTerm };
}

export const setSearchType = (searchType) => {
    return { type: SET_SEARCH_TYPE, payload: searchType };
}

export const setCurrentPage = (pageNumber) => {
    return { type: SET_CURRENT_PAGE, payload: pageNumber };
}
export const setSelectedSort = (selectedSort) => {
    return { type: SET_SELECTED_SORT, payload: selectedSort };
}

const setSortOptions = (sortOptions) => {
    return { type: SET_SORT_OPTIONS, payload: sortOptions };
}

const setSCID = (scid) => {
    return { type: SET_SCID, payload: scid };
}

const setSearchSummary = (matchesFound, timeTaken) => {
    var data = { matchesFound: matchesFound, timeTaken: timeTaken }
    return { type: SET_SEARCH_SUMMARY, payload: data };
}


const setCorrectedQuery = (correctedQuery) => {
    return { type: SET_CORRECTED_QUERY, payload: correctedQuery };
}


const setSearchResults = (searchResults) => {
    return { type: SET_SEARCH_RESULTS, payload: searchResults };
}




