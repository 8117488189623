import './search-results-page.css';
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SideBar from '../../shared/side-bar/side-bar';
import FilterContainer from '../../shared/filter/filter-container'
import ResultsContainer from './results-container'
import SummaryNavbar from './summary-navbar'
import SearchNavbar from "./search-navbar";
import {
    search
} from '../../../redux/actions/actions'
import Footer from '../../shared/footer/footer';


function SearchResulsPage(props) {


    const searchResultsLoading = useSelector(state => state.searchReducer.searchResultsLoading);
    const searchResults = useSelector(state => state.searchReducer.searchResults);
    const showFilters = useSelector(state => state.filterReducer.showFilters);

    const filters = useSelector(state => state.filterReducer.filters);


    const dispatch = useDispatch();

    useEffect(() => {
        if (searchResults.length === 0)
            dispatch(search());
    }, [])

    /**
     *
     */
    const onSearch = () => {

    }

    /**
     * Render cool stuff here
     * */
    return (
        <>
            <SummaryNavbar secondarySearch={onSearch} />
            <div className="wrapper">
                <SideBar showFilters={showFilters} zIndex={900}>
                    <FilterContainer
                        key={1}
                        isLoading={false}
                        filters={filters} />
                </SideBar>

                <div id="content">
                    <SearchNavbar isLoading={searchResultsLoading} />
                    <ResultsContainer searchResultsLoading={searchResultsLoading} getPageResults={onSearch} />
                </div>
            </div>
            <Footer isFixed={false} ></Footer>
        </>
    );
}

export default SearchResulsPage;

