import './mission-card.css'
import React, { useState, useEffect } from "react";
import {
    Card,
    Row,
    Col
} from 'react-bootstrap';
import placeHolderImage from '../../../assets/images/default-image.jpg';
import ImageService from "../../../services/image-service";

function MissionCard(props) {


    const [thumbnail, setThumbnail] = useState(null);

    useEffect(() => {
        ImageService.getProjectCover(props.mission.projectId).then((response) => {
            setThumbnail(response);
        })
    }, [props])


    /**
     * Render
     * */
    return (
        <div >

            <Card className='mt-3  image-card-body card-pointer' style={{ minWidth: '9rem' }} onClick={props.onClick}>
                <Card.Img className="card-pointer" onClick={props.onClickItemClick} variant="top" src={(thumbnail === null) ? placeHolderImage : thumbnail} alt={props.mission.projectId} />
                <Card.Body className='p-0 m-1 text-center'>
                    <Card.Title className='p-0 m-0'>
                        {props.mission.projectId}
                    </Card.Title>
                    <small className='p-0 m-0'>{props.mission.projectName}</small><br />
                    <small className='p-0 m-0'> {props.mission.createdTime.split('C')[0]}</small><br />


                </Card.Body>

            </Card>

            {/* <Card onClick={props.onClick} className='card-bottom-line card-pointer m-5' >
                <Row>
                    <Col xs={4} style={{width:"130px", height:"130px"}} >
                        <div style={{ height: '130px', width: '130px' }}>
                            <img src={(thumbnail === null) ? placeHolderImage : thumbnail} alt = {props.mission.projectId} width="100%" height="100%" />
                        </div>
                    </Col>
                    <Col className="ml-3 mt-2">
                        <Card.Title>{props.mission.projectId}</Card.Title>
                        <div> {props.mission.projectName}</div>

                        <div className="m-0 item-class"> {props.mission.createdTime.split('C')[0]}</div>
                    </Col>
                </Row>
            </Card> */}

        </div>
    );
}
export default MissionCard;
