import React from 'react';
import NavMenu from './NavMenu';

function Layout(props)  {
    return (
      <div>
        <NavMenu/>
        <>
          {props.children}
        </>
      </div>
    );
}
export default Layout;
