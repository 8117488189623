import { toast } from 'react-toastify';





export default class Notification {
    static defaultBaseError = "An error occured while getting ";

    static notifyError(message) {
        toast.error(message);
    }

    static notifySuccess(message) {
        toast.success(message);
    }

    static notifyInfo(message) {
        toast.info(message);
    }

    static notifyErrorRedirect(message, redirectLink) {
        toast.error(message, {
            hideProgressBar: true,
            autoClose: false,
            onClick: function () {
                window.location.href = redirectLink;
            }
        });
    }
}
