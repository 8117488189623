
import './image-details.css';
import React, { useEffect, useState, useRef } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
    Table,
    Container,
    Form,
    Row,
    Button,
    Col
} from 'react-bootstrap';
import OpenSeaDragon from "openseadragon";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faAngleLeft,
    faCamera,
    faChevronUp,
    faChevronDown,
    faSearchPlus,
    faSearchMinus,
    faExpand,
    faLocationArrow,
    faCompress,
    faDrawPolygon
} from '@fortawesome/free-solid-svg-icons';
import html2canvas from "html2canvas";
// import lottieLoading from '../../../assets/lottie/square-loading.json';
// import mapLoading from '../../../assets/lottie/map-loading.json';
import MapWrapper from "../../shared/open-tile/map-wrapper";
import {
    getImagePropertiesByName,
    getMissionFeaturesByName,
    setSearchTerm,
    setSearchType,
    getDocumentByLocation,
    getImageAnnotation,
    addImageAnnotation,
    updateImageAnnotation,
    deleteImageAnnotation
} from '../../../redux/actions/actions';
import * as Annotorious from '@recogito/annotorious-openseadragon';
import '@recogito/annotorious-openseadragon/dist/annotorious.min.css';
import config from '../../../config';
import DetailSection from './details-section';
import ImageCard from '../../shared/image-card/image-card';
import DetailsLoading from '../../shared/loaders/details-loading';
import MapLoading from '../../shared/loaders/map-loading';
import LabelingWidget from '../../shared/annotorious/labeling-widget';
import CommentingWidget from '../../shared/annotorious/comment-widget';
import notification from '../../shared/notification/notification';

function ImageDetails(props) {

    //Options for Lotto json (Used for Loading Icons)
    // const defaultOptions = {
    //     loop: true,
    //     autoplay: true,
    //     animationData: lottieLoading,
    //     rendererSettings: {
    //         preserveAspectRatio: 'xMidYMid slice'
    //     }
    // };

    // const mapOptions = {
    //     loop: true,
    //     autoplay: true,
    //     animationData: mapLoading,
    //     rendererSettings: {
    //         preserveAspectRatio: 'xMidYMid slice'
    //     }
    // };

    const searchResults = useSelector(state => state.searchReducer.searchResults);

    //Local State
    var imageUpdateKeyValue = 2;


    const [currentPhotoName, setCurrentPhotoName] = useState(null);
    const [projectId, setProjectId] = useState(null);

    const [imageProperties, setImageProperties] = useState(null);
    const [isPropertiesLoading, setIsPropertiesLoading] = useState(true);


    const [nextPhoto, setNextPhoto] = useState(null);
    const [imageUpdateKey, setImageUpdateKey] = useState(0);
    const [previousPhoto, setPreviousPhoto] = useState(null);
    const [oppositeDirectionPhoto, setOppositeDirectionPhoto] = useState(null);

    const [mapData, setMapData] = useState(null);
    const [displayMapData, setDisplayMapData] = useState(null);
    const [isAssetsOnly, setIsAssetsOnly] = useState(false);

    const [anno, setAnno] = useState(null);
    const [assetImages, setAssetImages] = useState([]);

    //Dispatch Redux Actions
    const dispatch = useDispatch();
    //Reference for OpenSeadron Element
    const [viewer, setViewer] = useState(null);
    const seaDragonElement = useRef();
    // const toolbar = useRef();
    const [tool, setTool] = useState('rect');
    const [isDrawing, setIsDrawing] = useState(false);
    /**
    * Default useEffect (Constructor)
    */
    useEffect(() => {
        setProjectId(props.match.params.projectId);
        setCurrentPhotoName(props.match.params.imageName);
        refreshMap();

    }, [])

    /**
     * When viewer changes
     */
    useEffect(() => {
        if (viewer !== null) {
            //Add event to refresh the map 
            //Solves issue causing map to be blank when
            //exiting full screen mode
            viewer.addHandler('pre-full-page', function (event) {
                if (event.fullPage === false) {
                    refreshMap();
                }
            });
            viewer.addHandler("page", function (data) {
                console.log(data);
            });


            //Hacky.. Timeout needed to let the view render
            // Add new button event
            // setTimeout(function () {
            //     var formatter = function (annotation) {
            //         var index = _.findIndex(annotation.bodies, (body) => {
            //             return body.purpose === "labeledBy";
            //         })
            //         if (index !== -1) {
            //             if (annotation.body[index].value === "Prediction") {
            //                 return "prediction";
            //             }
            //         }
            //         return "label";
            //     }
            //     const config = {
            //         widgets: [LabelingWidget],
            //         formatter: formatter
            //     };

            //     var localanno = Annotorious(viewer, config);

            //     localanno.on('cancelSelected', function (a) {
            //         setIsDrawing(false);
            //     });

            //     localanno.on('createAnnotation', function (a, overrideId) {
            //         setIsDrawing(false);
            //         var index = _.findIndex(a.body, (body) => {
            //             return body.purpose === "labelAttribute";
            //         })

            //         if (index !== -1) {
            //             if (a.body[index].value === null){
            //                 notification.notifyError("Unable to Save:  Image Label Missing Attribute");
            //                 return;
            //             }
            //             var attirbures = a.body[index].value.split(',');
            //             var emptyAttributeIndex = _.findIndex(attirbures, (attr) => {
            //                 return attr === ":";
            //             })
            //             if (emptyAttributeIndex === -1) {
            //                 dispatch(addImageAnnotation(a, projectId, currentPhotoName)).then(response => {
            //                     overrideId(response.id)
            //                 });
            //                 return;
            //             }
            //         }
            //         localanno.removeAnnotation(a);
            //         notification.notifyError("Unable to Save:  Image Label Missing Attribute");
            //     });

            //     localanno.on('deleteAnnotation', function (a) {
            //         // Do something
            //         console.log(a)
            //         dispatch(deleteImageAnnotation(a.id)).then(response => {

            //         });
            //     });
            //     localanno.on('updateAnnotation', function (a) {
            //         console.log(a)
            //         var index = _.findIndex(a.body, (body) => {
            //             return body.purpose === "labelAttribute";
            //         })

            //         if (index !== -1) {
            //             if (a.body[index].value === null){
            //                 notification.notifyError("Unable to Save:  Image Label Missing Attribute");
            //                 return;
            //             }
            //             var attirbures = a.body[index].value.split(',');
           
            //             var emptyAttributeIndex = _.findIndex(attirbures, (attr) => {
            //                 return attr === ":";
            //             })
            //             if (emptyAttributeIndex === -1) {
            //                 dispatch(updateImageAnnotation(a, projectId, currentPhotoName)).then(response => {
            //                     // overrideId(response.id)
            //                 });
            //                 return;
            //             }
            //         }
            //         notification.notifyError("Image Label Missing Attribute");
            //     });

            //     setAnno(localanno);

            //     dispatch(getImageAnnotation(projectId, currentPhotoName)).then((response) => {
            //         if (response !== null) {
            //             localanno.setAnnotations(response);
            //         }
            //     });
            // }, 50);
        }
    }, [viewer])




    /**
     * When imageProperties changes
     */
    useEffect(() => {
        setAssetImages([]);
        if (imageProperties) {
            setIsPropertiesLoading(false);
            InitOpenseadragon();
            getAssetImages();
            getOppositeDirectionImage();
        }
    }, [imageProperties])


    /**
    * When currentPhotoName changes
    */
    useEffect(() => {
        if (currentPhotoName && projectId) {
            props.history.push(`/image-details/${projectId}/${currentPhotoName}`);
            viewer && viewer.destroy();
            setOppositeDirectionPhoto(null);
            getImageProperties(currentPhotoName, projectId);
        }
    }, [currentPhotoName] || [projectId])


    useEffect(() => {
        if (isAssetsOnly) {
            let data = JSON.parse(JSON.stringify(mapData));
            toggleMapFeatures(data);
        } else {
            setDisplayMapData(mapData);
        }
    }, [isAssetsOnly])

    useEffect(() => {
        refreshMap();
    }, [displayMapData])



    /**
     * When "Next" arrow is clicked
     */
    const nextArrowClick = () => {
        setCurrentPhotoName(nextPhoto);
    }

    /**
     *When "Previous" arrow is clicked 
     */
    const previousArrowClick = () => {
        setCurrentPhotoName(previousPhoto);
    }

    const otherImageThumbClick = (imageName) => {
        setCurrentPhotoName(imageName);
    }
    const onDirectionChange = () => {
        setDisplayMapData(null);
        setCurrentPhotoName(oppositeDirectionPhoto);

    }

    /**
     * Refresh the Map when new image is selected
     */
    const refreshMap = () => {
        imageUpdateKeyValue++;
        setImageUpdateKey(imageUpdateKeyValue);
    }

    /**
     * Calls service to get image properties
     */
    const getImageProperties = (imageName, project) => {
        setIsPropertiesLoading(true)
        dispatch(getImagePropertiesByName(imageName, projectId))
            .then((response) => {
                if (response !== null) {

                    setImageProperties(response);
                    getMissionFeatures(response.projectId, response.cameraDirection);
                }
            });
    }

    const getAssetImages = () => {
        // if (imageProperties.assetNames === "")
        //     return;
        // var assets = imageProperties.assetNames.split(';');
        // for (var i = 0; i < assets.length; i++) {
        //     dispatch(getAssetImageByMission(imageProperties.projectId, assets[i]))
        //         .then((response) => {
        //             if (response !== null && response.length > 0) {
        //                 var newAsset = {asset:assets[i], images: response };
        //                 setAssetImages(state => [...state, newAsset])
        //             }
        //         })
        // }
    }


    const getOppositeDirectionImage = () => {

        var oppositeDirection = imageProperties.cameraDirection === "Oblique_Reverse" ? "Oblique_Forward" : "Oblique_Reverse"
        var splitCoord = imageProperties.imageCenter.split(',')
        dispatch(getDocumentByLocation(imageProperties.projectId, oppositeDirection, splitCoord[0], splitCoord[1])).then(response => {

            if (response !== null && response !== undefined) {
                setOppositeDirectionPhoto(response.imageName);
            }
        });
    }
    /**
     * Calls service to get missions features for the map
     */
    const getMissionFeatures = (projectId, imageType) => {
        dispatch(getMissionFeaturesByName(projectId, imageType))
            .then((response) => {
                if (response !== null) {
                    setMapData(response);
                    if (isAssetsOnly) {
                        let data = JSON.parse(JSON.stringify(response));
                        toggleMapFeatures(data);
                    }
                    else {
                        setDisplayMapData(response);
                    }
                }
            });
    }

    /**
     * Init OpenSeaDrago with set parameters
     */
    const InitOpenseadragon = () => {
        viewer && viewer.destroy();

        setViewer(
            OpenSeaDragon({
                autoResize: true,
                debugMode: false,
                debugGridColor: '#fff',
                loadTilesWithAjax: true,
                id: "openSeaDragon",
                prefixUrl: 'openseadragon/images/',
                animationTime: 0.3,
                blendTime: 0.1,
                constrainDuringPan: true,
                maxZoomPixelRatio: 5,
                minZoomLevel: 1,
                visibilityRatio: 1,
                toolbar: "toolbarDiv",
                zoomInButton: "zoom-in",
                zoomOutButton: "zoom-out",
                homeButton: "home",
                fullPageButton: "full-page",
                nextImageButton: "next",
                previousImageButton: "previous",
                tileSources: {
                    height: 8750,
                    width: 11664,
                    tileSize: 256,
                    getTileUrl: function (level, x, y) {
                        return config.apiUrl + `api/image/tiled/${currentPhotoName.split('.')[0]}/${level - 8}/${x}_${y}.?fileLocation=${imageProperties.imageURL}`;
                    }
                }
            })
        );
    }

    /**
     * Close the details pages
     */
    const backToSearch = () => {
        if (searchResults.length > 0) {
            props.history.push(`/search`);
        } else {
            dispatch(setSearchTerm(projectId));
            dispatch(setSearchType("projectId"));
            props.history.push({
                pathname: '/search',
                query: projectId
            });
        }
    }

    const onGISClick = (url) => {

        window.open(url);
    }

    const openGoogleMap = (cameraCenter) => {
        var coords = cameraCenter.split(',');
        window.open(`http://maps.google.com/maps?z=20&t=k&q=loc:${coords[0]}+${coords[1]}`);
    }

    const openSeaScreenShot = () => {
        html2canvas(document.querySelector("#openSeaDragon")).then(canvas => {
            let downloadLink = document.createElement('a');
            downloadLink.setAttribute('download', `${projectId}-${currentPhotoName}.png`);
            let dataURL = canvas.toDataURL('image/png');
            let url = dataURL.replace(/^data:image\/png/, 'data:application/octet-stream');
            downloadLink.setAttribute('href', url);
            downloadLink.click();
        });
    }

    const toggleMapFeatures = (data) => {

        var filteredMapFeatures = _.filter(data.features, function (feature) {
            if (feature['properties']['strCount'] !== 0 || feature['properties']['imageName'] === currentPhotoName) {
                return feature;
            }
        });
        // filteredMapFeatures = _.sortBy(filteredMapFeatures, 'imageName');
        var tempFeatures = data;
        tempFeatures.features = filteredMapFeatures
        setDisplayMapData(tempFeatures);
    }

    const toggleTool = () => {
        if (isDrawing) {
            anno.setDrawingEnabled(false);
            setIsDrawing(false);
            return;
        }
        setTool('rect');
        anno.setDrawingTool('rect');
        anno.setDrawingEnabled(true);
        setIsDrawing(true);
    }

    /**
    * Render
    * */
    return (
        <>
            <Container>
                {
                    <>

                        <div className={`overlay ${isDrawing ? "active" : ""}`} />
                        <Row>
                            <small className="float-left mb-4 mt-1 link-info link-pointer" onClick={() => backToSearch()}> <FontAwesomeIcon className="pointer mr-2" icon={faAngleLeft} size="sm" />Back to results</small>
                        </Row>
                        <Row className="mt-2 mb-5 center-div">
                            <h4> {isPropertiesLoading ? <DetailsLoading></DetailsLoading> : `${projectId} ${imageProperties.projectName}`}</h4>
                        </Row>

                        <Row>
                            <Col xs="12" md="6" lg="7" className="overlay-top" >
                                <div className="mx-auto block_container">
                                    <div>
                                        {nextPhoto ? <FontAwesomeIcon icon={faChevronUp} onClick={() => nextArrowClick()} className="fa-lg ml-1 mr-1 pointer" /> : <></>}
                                    </div>
                                    <div id="toolbarDiv" className="toolbar mx-auto">
                                        <FontAwesomeIcon className="fa-lg ml-1 mr-1 pointer" id="zoom-in" href="#zoom-in" title="Zoom in" icon={faSearchPlus} />
                                        <FontAwesomeIcon className="fa-lg ml-1 mr-1 pointer" id="zoom-out" href="#zoom-out" title="Zoom out" icon={faSearchMinus} />
                                        <FontAwesomeIcon className="fa-lg ml-1 mr-1 pointer" id="home" href="#home" title="Go home" icon={faCompress} />
                                        <FontAwesomeIcon className="fa-lg ml-1 mr-1 pointer" id="full-page" href="#full-page" title="Toggle full page" icon={faExpand} />
                                        <FontAwesomeIcon className="fa-lg ml-1 mr-1 pointer" icon={faCamera} title="Screenshot" onClick={openSeaScreenShot} />
                                        {/* <FontAwesomeIcon className={`fa-lg ml-1 mr-1 pointer icon  ${isDrawing ? "active" : ""}`} icon={faDrawPolygon} title="Labeling Tool" onClick={() => toggleTool()} /> */}
                                    </div>
                                    <div>
                                        {previousPhoto ? <FontAwesomeIcon icon={faChevronDown} onClick={() => previousArrowClick()} className="fa-lg ml-1 mr-1 pointer" /> : <></>}
                                    </div>
                                </div>

                                <Row className="mb-5">
                                    <Col >
                                        <div className="mx-auto ">
                                            <div
                                                id="openSeaDragon"
                                                ref={seaDragonElement}
                                                className="mx-auto"
                                                style={{
                                                    height: "600px"
                                                }}>


                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>

                            <Col>
                                <Row>
                                    {
                                        imageProperties && displayMapData !== null
                                            ?
                                            <div className="mt-0 mx-auto" key={imageUpdateKey}>
                                                <Row>
                                                    <div className="mx-auto block_container">
                                                        <div className="float-left">
                                                            <Form.Check onChange={({ target: { checked } }) => setIsAssetsOnly(checked)} type="checkbox" label="Structures Only" defaultChecked={isAssetsOnly} value={isAssetsOnly} />

                                                        </div>
                                                        <div className="text-center">
                                                            <span className="m-0"><FontAwesomeIcon size="sm" icon={faLocationArrow} className="mr-1" />{isPropertiesLoading ? <DetailsLoading></DetailsLoading> : <span className="m-0 link-info link-pointer" onClick={() => openGoogleMap(imageProperties.imageCenter)}>{imageProperties.imageCenter}</span>}</span>

                                                        </div>
                                                        <div className="float-right">
                                                            <Button className='float-right btn-sm mb-1' variant="info" onClick={() => onGISClick(imageProperties.egisUrl)}>eGIS</Button>

                                                        </div>
                                                    </div>
                                                </Row>

                                                <Row>
                                                    <div style={{
                                                        minWidth: "325px"
                                                    }}>
                                                        <MapWrapper updateKey={imageUpdateKey} features={displayMapData} currentPhotoName={currentPhotoName} nextPhotoHandler={setNextPhoto} previousPhotoHandler={setPreviousPhoto} center={imageProperties.imageCenter} zoom={16} onOverlayClick={setCurrentPhotoName} />
                                                    </div>
                                                </Row>

                                            </div>
                                            :
                                            <>
                                                <div className='mt-3' style={{

                                                }}>
                                                    <MapLoading></MapLoading>
                                                </div>
                                            </>
                                    }
                                </Row>
                                <Row className="center-div mt-2">
                                    <Col xs={4}>
                                        <div>

                                            <strong>Direction</strong>

                                            {oppositeDirectionPhoto ?
                                                <Form.Group controlId="kindOfStand">
                                                    <Form.Check
                                                        value="Oblique_Forward"
                                                        type="radio"
                                                        aria-label="Oblique_Forward"
                                                        label="Oblique_Forward"
                                                        onChange={() => onDirectionChange()}
                                                        checked={imageProperties.cameraDirection === "Oblique_Forward"}
                                                    />
                                                    <Form.Check
                                                        value="Oblique_Reverse"
                                                        type="radio"
                                                        aria-label="Oblique_Reverse"
                                                        label="Oblique_Reverse"
                                                        onChange={() => onDirectionChange()}
                                                        checked={imageProperties.cameraDirection === "Oblique_Reverse"}
                                                    />
                                                </Form.Group>
                                                : <>
                                                    <p>{isPropertiesLoading ? <DetailsLoading></DetailsLoading> : imageProperties.cameraDirection}</p>
                                                </>}

                                        </div>
                                    </Col>
                                    <Col xs={4}>
                                        <div>
                                            <strong>Azimuth</strong>
                                            <p>{isPropertiesLoading ? <DetailsLoading></DetailsLoading> : imageProperties.imageAzimuth} &#176; </p>
                                        </div>

                                    </Col>
                                    <Col xs={4}>
                                        <div>
                                            <strong>AGL</strong>
                                            <p> {isPropertiesLoading ? <DetailsLoading></DetailsLoading> : imageProperties.cameraAGL}</p>
                                        </div>

                                    </Col>
                                </Row>
                                <Row>
                                    <div style={{ height: "250px", overflowY: "scroll", overflowX: "hidden" }}>
                                        <Table striped hover className='ml-2 mr-2 mt-3' >
                                            <tbody>
                                                <tr>
                                                    <th>{isPropertiesLoading ? <DetailsLoading></DetailsLoading> : imageProperties.structures.includes(";") ? "Structures" : "Structure"}</th>
                                                    <td>{isPropertiesLoading ? <DetailsLoading></DetailsLoading> : imageProperties.structures !== "" ? imageProperties.structures.replace(/;/g, ", ") : "N/A"}</td>
                                                </tr>

                                                <tr>
                                                    <th>Line</th>
                                                    <td>{isPropertiesLoading ? <DetailsLoading></DetailsLoading> : imageProperties.tLofInterest}</td>
                                                </tr>
                                                <tr>
                                                    <th>Image ID</th>
                                                    <td>{isPropertiesLoading ? <DetailsLoading></DetailsLoading> : imageProperties.imageName}</td>
                                                </tr>
                                                <tr>
                                                    <th>Image Time</th>
                                                    <td>{isPropertiesLoading ? <DetailsLoading></DetailsLoading> : imageProperties.dateTime_CST}</td>
                                                </tr>
                                                <tr>
                                                    <th>Work Order</th>
                                                    <td>{isPropertiesLoading ? <DetailsLoading></DetailsLoading> : imageProperties.projectWorkOrder}</td>
                                                </tr>
                                                <tr>
                                                    <th>Mission</th>
                                                    <td>{isPropertiesLoading ? <DetailsLoading></DetailsLoading> : imageProperties.missionId}</td>
                                                </tr>
                                                <tr>
                                                    <th>Camera Location</th>
                                                    <td>{isPropertiesLoading ? <DetailsLoading></DetailsLoading> : imageProperties.cameraLocation}</td>
                                                </tr>
                                                <tr>
                                                    <th>Camera Lens</th>
                                                    <td>{isPropertiesLoading ? <DetailsLoading></DetailsLoading> : imageProperties.cameraLens}</td>
                                                </tr>
                                                <tr>
                                                    <th>Camera Model</th>
                                                    <td>{isPropertiesLoading ? <DetailsLoading></DetailsLoading> : imageProperties.cameraModel}</td>
                                                </tr>
                                                <tr>
                                                    <th>UNID</th>
                                                    <td dangerouslySetInnerHTML={{ __html: isPropertiesLoading ? <DetailsLoading></DetailsLoading> : imageProperties.unid !== "" ? imageProperties.unid.replace(/;/g, "<br/>") : "N/A" }}></td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </Row>
                            </Col>
                        </Row>
                        {
                            assetImages.length > 1
                                ?
                                assetImages && assetImages.map(function (asset, index) {
                                    return <DetailSection isLoading={false}
                                        title={`Other Asset ${asset.asset} Images`}>
                                        <div className="card-group card-group-scroll">
                                            {
                                                asset.images && asset.images.map(function (image, index2) {
                                                    if (image.imageName !== currentPhotoName)
                                                        return <Col lg={2} md={2} xs={12} key={index2}>
                                                            <ImageCard onClickItemClick={() => otherImageThumbClick(image.imageName)} thumbnail={image}></ImageCard>
                                                        </Col>
                                                })
                                            }
                                        </div>
                                    </DetailSection>
                                })

                                : null
                        }
                    </>
                }
            </Container>
            {/* <Footer isFixed={true} ></Footer> */}
        </>
    );
}

export default ImageDetails;
