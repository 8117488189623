import axios from 'axios';
// import notification from '../components/shared/notification/notification';
import config from '../config';

axios.defaults.headers.get['Pragma'] = 'no-cache';
axios.defaults.headers.get['Cache-Control'] = 'no-cache, no-store';

export default class SuggestionService {

    /**
     * Get Test Suggestiosn
     * @param {any} action
     */
    static getTestSuggestions(value, count) {
        return new Promise((resolve, reject) => {
            resolve([]);
        });
    }


    static getSearchSuggestions = (value, count) => {
        return axios.get(config.apiUrl + "api/suggest?term=" + value + "&top=" + count)
            .then(function (response) {
                if (response.status === 200) {
                    return response.data
                }
                throw response;
            })
            .catch(function (error) {
                console.log(error);
                // notification.notifyError(error.response.data.Detail);
                return null;
            });
    }

    static getWorOrderSuggestions = (value, count) => {

        return axios.get(config.apiUrl + `api/suggest?term=${value}&top=${count}&type=work order`)
            .then(function (response) {
                if (response.status === 200) {
                    return response.data
                }
                throw response;
            })
            .catch(function (error) {
                console.log(error);
                // notification.notifyError(error.response.data.Detail);
                return null;
            });
    }
}
