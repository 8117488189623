import React, {useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import {
  Navbar
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import config from '../config';
import logoSVG from '../assets/images/Capture.svg'


function NavMenu(props) {

  // const displayName = NavMenu.name;


  // const [collapsed, setCollapsed] = useState(true);

  useEffect(() => {
    // this.toggleNavbar = this.toggleNavbar.bind(this);
  }, [])


  // const toggleNavbar = () => {
  //   setCollapsed(!collapsed);
  // }

  /**
 * Called to go back to landing
 */
  const brandClickHandler = () => {
 
    props.history.push({pathname:'/'});
  }


  return (

    <Navbar bg="dark" variant="dark" className='m-0 p-0 dark-nav'>
      <div className="logo-pointer" onClick={ brandClickHandler}>
        <img height="75px" width="75px" src={logoSVG} alt=""></img>
        <span className="navbar-brand nav-pointer ml-2" style={{ letterSpacing: '3px' }}>{config.title}<sup><span style={{ fontSize: '10px' }}> {config.subtitle}</span></sup></span>
      </div>
    </Navbar>

  );
}
export default withRouter(NavMenu);

