import "./image-card.css";
import React, { useState, useEffect } from "react";
import {
    Card,
    OverlayTrigger,
    Tooltip,
    Button,
    ButtonToolbar,
    ButtonGroup,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faShareSquare,
} from '@fortawesome/free-solid-svg-icons';
import notification from '../notification/notification';
import tower from '../../../assets/images/Power-Lines.png';
import placeHolderImage from '../../../assets/images/default-image.jpg';
import ImageService from '../../../services/image-service';


function ImageCard(props) {

    const [thumbnail, setThumbnail] = useState(null);
    // const [isLoading, setIsloading] = useState(true);
    // const [imageFound, setImageFound] = useState(true);

    useEffect(() => {
        ImageService.getImageThumbnailByLocation(props.thumbnail.imageURL,'image').then((response) => {
            setThumbnail(response);
            // setIsloading(false);
        })
    }, [props])


    const creatOverlay = () => {
        var prefix = props.thumbnail.structures.includes(";") ? "Structures" : "Structure";
        return <Tooltip id={props.thumbnail.imageName}>
            {`${prefix}: ${props.thumbnail.structures.replace(/;/g, ", ")}`}
        </Tooltip >
    }
    /**
     * When user clicks copy link
     */
    const onCopyClick = () => {
        var textField = document.createElement('textarea')
        textField.style.position = "fixed";
        textField.innerText = `${window.location.origin}/image-details/${props.thumbnail.projectId}/${props.thumbnail.imageName}`
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.parentNode.removeChild(textField);
        //document.getElementById('clipBoardText').remove();
        //textField.remove()
        notification.notifySuccess(`${props.thumbnail.imageName} Details link copied to clipboard`);
    }


    /**
     * Render
     * */
    return (
        <>
            <Card className='mt-3  image-card-body card-pointer' style={{ minWidth: '10rem' }} >
                <Card.Img className="card-pointer" onClick={props.onClickItemClick} variant="top" src={(thumbnail === null) ? placeHolderImage : thumbnail} />
                <Card.Body className='p-0 m-1 text-center'>
                    <Card.Title className='p-0 m-0'>
                        {props.thumbnail.imageName}
                    </Card.Title>
                    <small className='p-0 m-0'>{props.thumbnail.projectId} ({props.thumbnail.projectName})</small><br />
                    <small className='p-0 m-0'>{props.thumbnail.cameraDirection}</small><br />

                    <ButtonToolbar className='p-0 m-0'>
                        <ButtonGroup size="sm" className="mx-auto">

                            <OverlayTrigger
                                key={`copy${props.thumbnail.imageName}`}
                                placement="bottom"
                                overlay={
                                    <Tooltip>
                                        Copy Details Link
                                    </Tooltip>
                                }>
                                <Button
                                    variant="link-primary"
                                    onClick={() => onCopyClick()}>
                                    <FontAwesomeIcon icon={faShareSquare} />
                                </Button>
                            </OverlayTrigger>

                            {props.thumbnail.structures !== "" ?
                                <>
                                    <OverlayTrigger
                                        key={props.thumbnail.imageName}
                                        placement="right"
                                        overlay={creatOverlay()}>
                                        <Button
                                            variant="link">
                                            <img height="14px" width="16px" src={tower} alt=""></img>
                                        </Button>
                                    </OverlayTrigger>
                                </>
                                : <div style={{ height: '15px' }}></div>
                            }

                        </ButtonGroup>
                    </ButtonToolbar>

                </Card.Body>

            </Card>
        </>
    );
}
export default ImageCard;
