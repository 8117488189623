import { createStore, applyMiddleware, combineReducers } from "redux";
import appReducer from './reducers/app-reducer';
import searchReducer from './reducers/search-reducer';
import filterReducer from './reducers/filter-reducer';

import { persistStore, persistReducer  } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import thunk from 'redux-thunk';

/*
Persist Config can be modified to change the way the state
persist.  Currently we store the redux state in the session storage
*/
const persistConfig = {
    key: 'root',
    storage: storageSession,
    stateReconciler: autoMergeLevel2
};


/*
Create a combined reducer that will persist in the storage session
Keeps state saved after refresh the best way to clear state is open a 
new window
*/
const pReducer = persistReducer(persistConfig, combineReducers({
    appReducer,
    searchReducer,
    filterReducer
}));

/*
Apply thunk middleware
This allows the react actions to return a function and call other 
action using the dispatcher
*/
const store = createStore(pReducer, applyMiddleware(thunk));
const persistor = persistStore(store);
export {
    store,
    persistor
};
