import React from "react";
import './faq-page.css';
import {
    Container,
    Row,
    Col
} from 'react-bootstrap';
// import BackButton from "../../shared/back-button/back-button";



function FAQPage() {



    /**
    * Render
    */
    return (
        <>
            {/* <BackButton /> */}

            <Container className="faqsBody">
                <Row>
                    <Col>
                        <h4>HeliViewer </h4>
                        <br />
                        <div>HeliViewer provides a feature rich search interface to quickly helicopter images by searching across a wide range of fields/attributes.
                            As of now, searches can be performed across the following fields:<br /><br />

                                 Mission Id, Project Id, Project Name, Image Name, Structures, Project Work Order, Tranmission Line, UNID, Camera Direction, and Longitude and Latitude.
                        </div>
                    </Col>
                </Row>
                <br />
                <br />
                <br />
                <h4>HeliViewer Search Guide </h4>
                <br />
                <Row>
                    <Col>
                        <h5>Wildcard Searches:</h5>
                        <div>
                        HeliViewer supports single and multiple character wildcard searches.  <br /><br />
                            <ul>
                                <li>To perform a single character wildcard search, use the “?” symbol. </li>
                                <li>To perform a multiple character wildcard search, use the “*” symbol. This is the default search option in HeliViewer. In the absence of the “*” symbol,
                                the search defaults to a multiple character wildcard search.
                                </li>
                            </ul>

                        As an example, to search for all items with first 4 letters of “C013”
                        and also contain the term "falling", the search input can be specified as shown in the screenshot below.
                        </div>
                        <div className="border mt-3">
                            <img className="img-fluid rounded mx-auto d-block" src={require("../../../assets/faq/faq-1.PNG")} alt=""></img>
                        </div>
                    </Col>
                </Row>
                <br />
                <br />
                <br />
                <h5>Combinational Searches:</h5>
                <Row>
                    <Col>
                        <div>
                        HeliViewer supports specifying multiple terms in the search input that are combined using the following logic operators:
                            <br />
                            <br />
                            <ul>

                                <li
                                ><strong>AND</strong><br />
                                Multiple search terms can be combined using the “AND” operator. For example, to search for all items that contain the asset 27 and line “L5746”,
                                the search input can be specified as shown in the screenshot below.
                                    <br />
                                    <div className="border mt-3">
                                        <img className="img-fluid rounded mx-auto d-block" src={require("../../../assets/faq/faq-2.PNG")} alt=""></img>
                                    </div>
                                    <br />
                                </li>

                                <li><strong>OR</strong><br />
                                In the absence of “AND” and “NOT” operators, HeliViewer always performs a search that retrieves results containing either any or all the terms specified as
                                shown in the screenshot below.
                                    <div className="border mt-3">
                                        <img className="img-fluid rounded mx-auto d-block" src={require("../../../assets/faq/faq-3.PNG")} alt=""></img>
                                    </div>
                                    <br />
                                    <br />
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
                <br />
                <br />
                <br />
                <h5>Longitude and Latitude Search:</h5>
                <Row>
                    <Col>
                        <div>
                        Longitude and Latitude can be used to when searching.  All images within a 500 mile radius will be retrieved as
                                shown in the screenshot below.
                            <div className="border mt-3">
                                <img className="img-fluid rounded mx-auto d-block" src={require("../../../assets/faq/faq-4.PNG")} alt=""></img>
                            </div>
                        </div>
                    </Col>
                </Row>
                <br />
                <br />
                <br />


            </Container>
        </>
    );
}
export default FAQPage;
