import React from "react"
import ContentLoader from "react-content-loader"

const MapLoading = (props) => (
  <ContentLoader 
    speed={2}
    width={'100%'}
    height={'100%'}
    viewBox="0 0 540 340"
    backgroundColor="#404040" 
    foregroundColor="#303030"
    {...props}
  >
    <rect x="9" y="1" rx="2" ry="2" width='100%' height='100%' />
  </ContentLoader>
)

export default MapLoading