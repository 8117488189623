import axios from 'axios';
import config from '../config';
import { authProvider } from './auth-service';
import notification from '../components/shared/notification/notification';
axios.defaults.headers.get['Pragma'] = 'no-cache';
axios.defaults.headers.get['Cache-Control'] = 'no-cache, no-store';

export default class ImageService {


    static getImagePropertiesByName = async (imageName, projectId) => {
        var token = await authProvider.getAccessToken();
        return axios.get(config.apiUrl + `api/image/properties/${imageName}/${projectId}`,
            {
                headers: {
                    Authorization: 'Bearer ' + token.accessToken,
                }
            })
            .then((response) => {
                if (response.status === 200) {

                    return response.data;
                }
                throw response;
            })
            .catch((error) => {
                console.log(error);
                return null;
            })
    };

    static getImageAnnotation = async (projectId, imageName) => {
        var token = await authProvider.getAccessToken();
        return axios.get(config.apiUrl + `api/image/annotation/${projectId}/${imageName}`,
            {
                headers: {
                    Authorization: 'Bearer ' + token.accessToken,
                }
            })
            .then((response) => {
                if (response.status === 200) {

                    return response.data;
                }
                throw response;
            })
            .catch((error) => {
                console.log(error);
                return null;
            })
    };

    static addImageAnnotation = async (annotation) => {
        var token = await authProvider.getAccessToken();
        annotation['labeledBy'] = authProvider.account.userName;
        return axios.post(config.apiUrl + `api/image/annotation/add`,annotation,
            {
                headers: {
                    Authorization: 'Bearer ' + token.accessToken,
                }
            })
            .then((response) => {
                if (response.status === 200) {
                    notification.notifySuccess("Image Label Added");
                    return response.data;
                }
                throw response;
            })
            .catch((error) => {
                console.log(error);
                return null;
            })
    };

    static updateImageAnnotation = async (annotation) => {
        var token = await authProvider.getAccessToken();
        annotation['labeledBy'] = authProvider.account.userName;
        return axios.post(config.apiUrl + `api/image/annotation/update`,annotation,
            {
                headers: {
                    Authorization: 'Bearer ' + token.accessToken,
                }
            })
            .then((response) => {
                if (response.status === 200) {
                    notification.notifySuccess("Label Updated");
                    return response.data;
                }
                throw response;
            })
            .catch((error) => {
                console.log(error);
                return null;
            })
    };


    static deleteImageAnnotation = async (annotationId) => {
        var token = await authProvider.getAccessToken();
        return axios.post(config.apiUrl + `api/image/annotation/delete/${annotationId}`,{},
            {
                headers: {
                    Authorization: 'Bearer ' + token.accessToken,
                }
            })
            .then((response) => {
                if (response.status === 200) {
                    notification.notifySuccess("Label Removed");
                    return response.data;
                }
                throw response;
            })
            .catch((error) => {
                console.log(error);
                return null;
            })
    };

    static getAssetImageByMission = async (missionName, assetName) => {
        var token = await authProvider.getAccessToken();
        return axios.get(config.apiUrl + `api/image/assetImage/${missionName}/${assetName}`,
            {
                headers: {
                    Authorization: 'Bearer ' + token.accessToken,
                }
            })
            .then((response) => {
                if (response.status === 200) {

                    return response.data;
                }
                throw response;
            })
            .catch((error) => {
                console.log(error);
                return null;
            })
    };

    static getImageThumbnailByLocation = async (location, imageName) => {
        var token = await authProvider.getAccessToken();
        return axios.get(config.apiUrl + `api/image/thumb/${imageName}?fileLocation=${location}`,
            {
                headers: {
                    Authorization: 'Bearer ' + token.accessToken,
                }
            })
            .then((response) => {
                if (response.status === 200) {

                    return "data:image/jpg;base64," + response.data;
                }
                throw response;
            })
            .catch((error) => {
                console.log(error);
                return null;
            })
    };

    static getProjectCover = async (projectId) => {
        var token = await authProvider.getAccessToken();
        return axios.get(config.apiUrl + `api/image/projectCover/${projectId}`,
            {
                headers: {
                    Authorization: 'Bearer ' + token.accessToken,
                }
            })
            .then((response) => {
                if (response.status === 200) {

                    return "data:image/jpg;base64," + response.data;
                }
                throw response;
            })
            .catch((error) => {
                console.log(error);
                return null;
            })
    };

    static getDocumentBylocation = async (projectId, cameraDirection, lat, lon) => {
        var token = await authProvider.getAccessToken();
        return axios.get(`${config.apiUrl}api/image/cameraDirection/properties/${projectId}?direction=${cameraDirection}&lat=${lat}&lon=${lon}`,
            {
                headers: {
                    Authorization: 'Bearer ' + token.accessToken,
                }
            }
        ).then((response) => {
            if (response.status === 200) {
                return response.data;
            }
            throw response;
        })
            .catch((error) => {
                console.log(error);
                // notification.notifyError(error.response.data.Detail);
                return null;
            })
    }

    static getImageByName = async (imageName) => {
        var token = await authProvider.getAccessToken();
        return axios.get(config.apiUrl + `api/image/getImage`,
            {
                headers: {
                    Authorization: 'Bearer ' + token.accessToken,
                }
            })
            .then((response) => {
                if (response.status === 200) {
                    console.log(response.data)
                    var blb;
                    // Make the raw data into a blob.
                    // BlobBuilder fallback adapted from
                    // http://stackoverflow.com/questions/15293694/blob-constructor-browser-compatibility
                    try {
                        blb = new window.Blob([response.data], { type: "image/jpg" });
                        console.log("Blob");
                    } catch (e) {
                        var BlobBuilder = (
                            window.BlobBuilder ||
                            window.WebKitBlobBuilder ||
                            window.MozBlobBuilder ||
                            window.MSBlobBuilder
                        );
                        if (e.name === 'TypeError' && BlobBuilder) {
                            var bb = new BlobBuilder();
                            bb.append(response.data);
                            blb = bb.getBlob();
                        }
                    }

                    // Create a URL for the blob data and make it the source of the image object.
                    // This will still trigger Image.onload to indicate a successful tile load.
                    var url = (window.URL || window.webkitURL).createObjectURL(blb);
                    console.log(url);
                    return url;
                }
                throw response;
            })
            .catch((error) => {
                console.log(error);
                return null;
            })
    };
}

