import AppService from "../../services/app-service";

export const SET_PAGE_RESULTS = 'SET_SEARCH_RESULTS';
export const SET_IS_RESULTS_LOADING = 'SET_IS_RESULTS_LOADING';
export const SET_IS_SHOWING_SIDEBAR = 'SET_IS_SHOWING_SIDEBAR'


// Seaching
export const getPageResults = (fetch = 12, offset = 0) => {
    return async (dispatch) => {

        // dispatch(setCurrentPage(1));
        dispatch(isResultsLoading(true));
        AppService.getPageResults(fetch, offset).then(response => {
            if (response !== null) {
                dispatch(setPageResults(response));
            }
            dispatch(isResultsLoading(false));
        });
    };
}



//Reducer Function**
const isResultsLoading = (isLoading) => {
    return { type: SET_IS_RESULTS_LOADING, payload: isLoading };
}
const setPageResults = (results) => {
    return { type: SET_PAGE_RESULTS, payload: results };
}
export const setShowSidebar = (isShowing) => {
    return { type: SET_IS_SHOWING_SIDEBAR, payload: isShowing };
}
