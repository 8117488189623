import axios from 'axios';
import config from '../config';


axios.defaults.headers.get['Pragma'] = 'no-cache';
axios.defaults.headers.get['Cache-Control'] = 'no-cache, no-store';

export default class AppService {


    static getPageResults = async (fetch = 12, offset = 0) => {
        return axios.get(config.apiUrl + `api/files/images?fetch=${fetch}&offset=${offset}`)
            .then((response) => {
                if (response.status === 200) {
                    return response.data;
                }
                throw response;
            })
            .catch((error) => {
                console.log(error);
                return null;
            })
    };
}