import MissionService from '../../services/mission-service';

/**
 * Get mission features for map from service
 * @param {*} missionName - name of the mission
 * @returns list of map features
 */
export const getMissionFeaturesByName = (projectId, imageType) => {
    return () => {
        //Call to mission service
        return MissionService.getMissionFeaturesByName(projectId, imageType)
            .then((response) => {
                return response;
            })
    };
}

export const getLatestMissions = () => {
    return () => {
        //Call to mission service
        return MissionService.getLatestMissions()
            .then((response) => {
                return response;
            })
    };
}