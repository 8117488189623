import React from "react"
import ContentLoader from "react-content-loader"

const DetailsLoading = (props) => (
  <ContentLoader 
  speed={2}
  width={205}
  height={16}
  viewBox="0 0 205 16"
  backgroundColor="#404040" 
  foregroundColor="#303030"
  {...props}
>
  <rect x="4" y="3" rx="3" ry="3" width="128" height="9" />
</ContentLoader>
)

export default DetailsLoading