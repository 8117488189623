import './auto-suggest.css';
import React, { useState, useRef, useEffect } from "react";
import Autosuggest from 'react-autosuggest';

function SuggestTextBox(props) {

    const [suggestions, setSuggestions] = useState([]);

    const ref = useRef();
    const unmounted = useRef(false);

    useEffect(() => {
        return () => { unmounted.current = true }
    }, []);


    const getSuggestions = (value) => {
        return props.getSuggestions(value, props.suggestionCount).then(response => {
            if (!unmounted.current)
                return response
                    .map(section => {
                        return {
                            type: section.type,
                            values: section.values
                        };
                    })
        });
    }


    function getSuggestionValue(suggestion) {
        return suggestion;
    }


    function renderSuggestion(suggestion) {
        return (
            <div dangerouslySetInnerHTML={{ __html: suggestion[1] }}></div>
        );
    }


    function renderSectionTitle(section) {
        return (
            <small className="text-uppercase">{section.type}</small>
        );
    }


    function getSectionSuggestions(section) {
        return section.values;
    }


    const onChange = (event, { newValue, sectionIndex, method }) => {

        if (method === 'type') {
            props.setValue(newValue);
            if (props.setType)
                props.setType("");
            getSuggestions(newValue).then(suggestions => {
                setSuggestions(suggestions);
            });
        } else if (method === 'up' || method === 'down') {
            if (props.setType)
                props.setType(newValue[2]);

            props.setValue(newValue[0]);
        } else if (method === 'click') {
            if (props.setType)
                props.setType(newValue[2]);
            props.setValue(newValue[0]);
        } else {
            props.setValue(newValue[0]);
        }
    }


    const onSuggestionSelected = (event, { suggestion, suggestionValue, sectionIndex, method }) => {
        props.setValue(suggestion[0]);
        if (ref.current.props.inputProps.onKeyDown && method === 'click') {
            var eventPress = document.createEvent('Event');
            eventPress.key = 'Enter';
            setTimeout(() => {
                if (ref.current)
                    ref.current.props.inputProps.onKeyDown(eventPress);
            }, 20);
        }
    }


    const inputProps = {
        placeholder: props.placeholder,
        // onSuggestionSelected:onSuggestionSelected,
        value: props.value,
        onKeyDown: props.handleKeyPress,
        onChange: onChange,
        disabled: props.disabled
    };


    return (
        <>
            {props.multiSection ?
                <Autosuggest
                    ref={ref}
                    multiSection={true}
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={() => { }}
                    onSuggestionsClearRequested={() => { }}
                    onSuggestionSelected={onSuggestionSelected}
                    focusInputOnSuggestionClick={false}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    renderSectionTitle={renderSectionTitle}
                    getSectionSuggestions={getSectionSuggestions}
                    inputProps={inputProps}
                />
                :
                <Autosuggest
                    ref={ref}
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={() => { }}
                    onSuggestionsClearRequested={() => { }}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    getSectionSuggestions={getSectionSuggestions}
                    inputProps={inputProps}
                />
            }

        </>
    );
}

export default SuggestTextBox;

