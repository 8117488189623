import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pagination, Spinner, Row, CardDeck, Col } from 'react-bootstrap';
import { withRouter } from "react-router";
import { setCurrentPage, getNextPage, setSearchTerm, search } from '../../../redux/actions/actions'
// import SearchResultItem from '../../shared/search-result-item/search-result-item'
import ImageCard from '../../shared/image-card/image-card';

function ResultsContainer(props) {

    //Redux State
    const searchTerm = useSelector(state => state.searchReducer.searchTerm);
    const searchResults = useSelector(state => state.searchReducer.searchResults);
    const pageSize = useSelector(state => state.searchReducer.pageSize);
    const currentPage = useSelector(state => state.searchReducer.currentPage);
    const matchesFound = useSelector(state => state.searchReducer.matchesFound);
    const correctedQuery = useSelector(state => state.searchReducer.correctedQuery);

    // const correctedQuery = "Breaker";
    const dispatch = useDispatch();

    /**
     * Creates the pagination element
     * */
    const getPagination = () => {
        let pages = getMaxPages();
        if (pages > 1) {
            return <Pagination variant="light" className="text-primary">
                <Pagination.First onClick={() => changePages(1)} />
                <Pagination.Prev onClick={() => changePages(currentPage - 1)} />

                {(currentPage > 3) ? <Pagination.Item onClick={() => changePages(currentPage - 3)}>{(currentPage - 3).toLocaleString('en-US', { minimumFractionDigits: 0 })}</Pagination.Item> : <></>}
                {(currentPage > 2) ? <Pagination.Item onClick={() => changePages(currentPage - 2)}>{(currentPage - 2).toLocaleString('en-US', { minimumFractionDigits: 0 })}</Pagination.Item> : <></>}
                {(currentPage > 1) ? <Pagination.Item onClick={() => changePages(currentPage - 1)}>{(currentPage - 1).toLocaleString('en-US', { minimumFractionDigits: 0 })}</Pagination.Item> : <></>}

                <Pagination.Item active>{currentPage.toLocaleString('en-US', { minimumFractionDigits: 0 })}</Pagination.Item>

                {(currentPage < pages) ? <Pagination.Item onClick={() => changePages(currentPage + 1)}>{(currentPage + 1).toLocaleString('en-US', { minimumFractionDigits: 0 })}</Pagination.Item> : <></>}
                {(currentPage < pages - 1) ? <Pagination.Item onClick={() => changePages(currentPage + 2)}>{(currentPage + 2).toLocaleString('en-US', { minimumFractionDigits: 0 })}</Pagination.Item> : <></>}
                {(currentPage < pages - 2) ? <Pagination.Item onClick={() => changePages(currentPage + 3)}>{(currentPage + 3).toLocaleString('en-US', { minimumFractionDigits: 0 })}</Pagination.Item> : <></>}


                <Pagination.Next onClick={() => changePages(currentPage + 1)} />
                <Pagination.Last onClick={() => changePages(pages)} />
            </Pagination>
        }
    }


    /**
     * Calucates the max pages based on the search
     * */
    const getMaxPages = () => {
        return Math.ceil(matchesFound / pageSize);
    }


    /**
     * Called to change pages
     * @param {any} pageNumber - the selected page number
     * @param {any} event - Click event
     */
    const changePages = (pageNumber) => {
        //Get max pages
        let pages = getMaxPages();

        //Check if the page can be changed
        if (pageNumber > 0 && pageNumber <= pages && pageNumber !== currentPage) {
            //Move back to the top of the page
            window.scrollTo(0, 0);
            //Set new state and get page results
            dispatch(setCurrentPage(pageNumber));
            dispatch(getNextPage());
        }
    }

    /**
     * Called by child Component (Search result item) is clicked
     * @param {any} result
     */
    const onResultClickHandler = (result) => {
        props.history.push(
            {
                pathname: `/image-details/${result.projectId}/${result.imageName}`,
                state: result
            });
    }

    const correctQueryHandler = () =>{
        dispatch(setSearchTerm(correctedQuery));
        dispatch(search());
    }

    /**
     * Render
     */
    return (
        <>
            {!props.searchResultsLoading
                ?
                <>
                    {searchResults.length === 0
                        ?
                        <Row className="justify-content-center"><h6>No Results found for {searchTerm}</h6></Row>
                        :
                        <>

                            {correctedQuery !== null ?
                                <div className="ml-3 mb-4 mt-0 text-danger">Did you mean: <span onClick={() => correctQueryHandler()} className="text-primary accent-hover pointer">{correctedQuery}</span></div>
                                : null}

                            <div>
                                {
                                    // searchResults && searchResults.map(function (result, i) {
                                    //     return <SearchResultItem key={i} item={result} itemClickHandler={onResultClickHandler} />
                                    // })
                                    <CardDeck>
                                    {
                                        searchResults.length > 0 && searchResults.map((result, index) => {
                                            return (<Col lg={3} md={4} xs={12} key={index} className="card-boarder">
                                                <ImageCard onClickItemClick={()=> onResultClickHandler(result)}  thumbnail={result}></ImageCard>
                                            </Col>
                                            )
                                        })
                                    }
                                </CardDeck>
                                }
                            </div>
                            {/* <CardDeck>
                                {
                                    
                                    props.searchResults && props.searchResults.map((result, i) =>
                                        (<Col key={i} lg={3} md={4} sm={6} sx={12}>
                                            <ItemCard item={result} itemClickHandler={onResultClickHandler.bind(this)} />
                                        </Col>)

                                    )
                                }
                            </CardDeck> */}
                            <div>
                                <br />
                                <br />
                                {!props.searchResultsLoading && getPagination()}
                            </div>
                        </>
                    }
                </>
                :
                <>

                    <Row className="justify-content-center mt-5">
                        <Spinner animation="border" variant="secondary" />
                    </Row>
                </>
            }
        </>
    );
}
export default withRouter(ResultsContainer);