import React from "react";
import {
    Row,
    Spinner
} from 'react-bootstrap';


function DetailSection(props) {

    /**
     * Render
     */
    return (
        <div>
            {

                !props.isLoading ?
                    <>
                        <hr />
                        <div className="details-section  mb-5 mt-5">
                            <h4 className="text-secondary  mb-3">{props.title}</h4>
                            {props.children}
                            <br />
                        </div>
                        
                    </>
                    :
                    <>
                        <Row className="justify-content-center mt-3">
                            <Spinner animation="border" variant="secondary" />
                        </Row>
                    </>
            }
        </div>
    );
}
export default DetailSection;
