import React from "react";
// import { useDispatch } from "react-redux";
import { withRouter } from 'react-router-dom';
import {
    Nav,
    Row,
    Col
} from 'react-bootstrap';
import config from "../../../config";
// import logo from "../../../assets/images/tva.png";


import './footer.css';

function Footer(props) {

    // const [show, setShow] = useState(false);
    // const dispatch = useDispatch();


    /**
     * Go to help
     */
    const helpClickHandler = () => {
        props.history.push('/faqs');
    }

    // /**
    //  * User Click Feedback Icon
    //  */
    // const modalShowClickHandler = () => {
    //     setShow(true);
    // }


    // /**
    //  * Called to close modal
    //  */
    // const modalHideClickHandler = () => {
    //     setShow(false);
    // }


    return (
        <>
            <footer className={(props.isFixed ? 'footer-abs' : '') + ' footer'}>
                <Row className="footer-nav">
                    <Col>
                        <Nav>
                            {/* <img src={logo} className="m-3 p-0" width="35px" height="15px"></img> */}
                            <Nav.Link className="link-secondary" onClick={helpClickHandler}>How to Search</Nav.Link>
                            {config.env === "prod"
                                ?
                                <Nav.Link className="ml-auto link-secondary">HeliViewer {config.buildNumber}</Nav.Link>
                                :
                                <Nav.Link className="ml-auto link-secondary">HeliViewer {config.env}</Nav.Link>
                            }

                        </Nav>
                    </Col>
                </Row>
            </footer>
        </>
    );
}

export default withRouter(Footer);
