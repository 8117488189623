import 'bootstrap/dist/css/bootstrap.css';
import './assets/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';

import { Provider } from 'react-redux';
import { store, persistor } from './redux/store';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { AzureAD } from 'react-aad-msal';
import { authProvider } from './services/auth-service';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
  <AzureAD provider={authProvider} forceLogin={true}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter basename={baseUrl}>
          <App />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </AzureAD>,
  rootElement);

registerServiceWorker();

