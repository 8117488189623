import {
    SET_SEARCH_TERM,
    SET_SEARCH_TYPE,
    SET_SORT_OPTIONS,
    SET_SELECTED_SORT,
    SET_CURRENT_PAGE,
    SET_SEARCH_RESULTS,
    SET_IS_SEARCH_RESULTS_LOADING,
    SET_SEARCH_SUMMARY,
    SET_CORRECTED_QUERY,
    SET_SCID
} from '../actions/actions';


/*
Create intial state for the Search
Store global search state here
*/
const initialSearchState = {
    scid: "",
    correctedQuery: null,
    searchTerm: "",
    searchType: "",
    sortOptions: [],
    selectedSort: "",
    searchResults: [],
    searchResultsLoading: false,
    pageSize: 24,
    currentPage: 1,
    matchesFound: 0,
    timeTakenMs: 0,
};


function searchReducer(state = initialSearchState, action) {
    switch (action.type) {
        case SET_SCID:
            return {
                ...state,
                scid: action.payload
            };
        case SET_IS_SEARCH_RESULTS_LOADING:
            return {
                ...state,
                searchResultsLoading: action.payload
            };
        case SET_SEARCH_TERM:
            return {
                ...state,
                searchTerm: action.payload
            };
        case SET_SEARCH_TYPE:
            return {
                ...state,
                searchType: action.payload
            };
        case SET_SEARCH_RESULTS:
            return {
                ...state,
                searchResults: action.payload
            };
        case SET_SEARCH_SUMMARY:
            return {
                ...state,
                matchesFound: action.payload.matchesFound,
                timeTakenMs: action.payload.timeTaken,
            };
        case SET_SORT_OPTIONS:
            return {
                ...state,
                sortOptions: action.payload
            };
        case SET_SELECTED_SORT:
            return {
                ...state,
                selectedSort: action.payload
            };
        case SET_CORRECTED_QUERY:
            return {
                ...state,
                correctedQuery: action.payload
            };
        case SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload
            };
        default:
            return state;
    };
}
export default searchReducer;
